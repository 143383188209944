import React, { useEffect, useState } from 'react'
import YearData from './YearData'
import TypeData from './TypeData'
import YearRatio from './YearRatio'
import ProvinceData from './ProvinceData'

const HistoryData = (props) => {
  const { data } = props
  const [yearData, setYearData] = useState()
  const [typeData, setTypeData] = useState()
  const [yearRatio, setYearRatio] = useState()
  const [provinceData, setProvinceData] = useState()

  useEffect(() => {
    if (data) {
      setYearData(data[0])
      setTypeData(data[1])
      setYearRatio(data[2])
      setProvinceData(data[3])
    }
  }, [data])

  return (
    <div>
      <YearData data={yearData} />
      <TypeData data={typeData} />
      <YearRatio data={yearRatio} />
      <ProvinceData data={provinceData} />
    </div>
  )
}

export default HistoryData