import React, { useState, useEffect, useCallback } from "react"
import { Link } from 'react-router-dom'
import { Space, List } from 'antd'
import style from './case.module.scss'
import PaneHeader from '../PaneHeader'
import _ from 'lodash'
const { Item } = List

/* 
  案件列表
 */
const CaseList = (props) => {
  const { name, pos, caseCount, locationCount, subname, subpos, personCount, caseTypes, data } = props
  const [relationshipType, setRelationshipType] = useState()
  const [caseType, setCaseType] = useState()
  const [matchData, setMatchData] = useState()
  const [navMenu, setnavMenu] = useState([{title: '关系类型', default: '关系类型', list: 'relationshipTypes'}, {title: '案件类型', default: '案件类型', list: 'caseTypes'}, {title: '律师事务所', default: '律师事务所', list: 'location'}]) 
 
  // 点击 下拉菜单选项
  const selectItem = useCallback((item, index) => {
    switch (index) {
      case 0:
        item.checked ? setRelationshipType(item.title) : setRelationshipType()
        break
      case 1:
        item.checked ? setCaseType(item.title) : setCaseType()
        break
      default:
        break
    }
    const navmenu = JSON.parse(JSON.stringify(navMenu))
    navmenu[index].title = item.checked ? item.title : navmenu[index].default
    setnavMenu(navmenu)
  }, [navMenu])


  useEffect(() => {
    if (data) {
      // 格式化数据 根据关系类型、案件类型、律所等
      const {directCases, relationCases} = data

      let matchData = []
      switch (relationshipType) {
        case '直接关系':
          matchData = directCases || []
          break
        case '间接关系':
          matchData = relationCases || []
          break
        default:
          if (_.isArray(directCases))
            matchData = matchData.concat(directCases)
          if (_.isArray(relationCases))
            matchData = matchData.concat(relationCases)
          break
      }
      if (caseType) {
        matchData = matchData ? matchData.filter(item => item.reasonId === caseType) : []
      }
      setMatchData(matchData)

    }
  }, [data, caseType, relationshipType])

  return (
    <div className={style.caseList}>
      <div className={style.header + ' clearfix'}>
        <h4 className={style.title}>
          <Space>
            <span className={pos.indexOf('律') < 0 ? style.judge : style.lawyer}>{name}</span>
            <span className={pos.indexOf('律') < 0 ? style.judge : style.lawyer}>{pos}</span>
            和
            <span className={(subpos==='同事' && pos==='法官') || subpos.indexOf('法') > -1 ? style.judge : style.lawyer}>{subname}</span>
            <span className={(subpos==='同事' && pos==='法官') || subpos.indexOf('法') > -1 ? style.judge : style.lawyer}>{subpos==='同事' ? pos : subpos}</span>
            合作的案件汇总
          </Space>
        </h4>
        <PaneHeader subpos={subpos} name={name} pos={pos} navMenu={navMenu} selectItem={selectItem} caseTypes={caseTypes} />
      </div>
      <div className={style.caseListWrapper}>
        <List
          className={style.list}
          pagination={{}}
          dataSource={matchData}
          renderItem={(item, i) => (
              <Item key={i} className={style.item}>
                <Link className={style.caseContent} to={`/judgesRelationships/cases/detail?caseCount=${caseCount}&locationCount=${locationCount}&personCount=${personCount}&caseNo=${item.caseNo}`}>
                  <p className={style.title}>{item.title}</p>
                  <div className={style.abstract}>{item.abstract + '...'}</div>
                  <div className={style.info}>
                    <Space size={15}>
                      <p>日期：{item.date}</p>
                      <p>关系类型：{item.type === 'directCases' ? '直接关系' : '间接关系'}</p>
                      {
                        subpos === '律师' 
                        ? <p>合作该案时所在律所：<span className={style.lawyer}>{item.lawFirm}</span></p>
                        : subpos === '律所'
                          ? <p>该案参与律师：<span className={style.lawyer}>{item.lawyer}</span></p>
                          : null
                      }
                      <p>类型：{item.docType}</p>
                      <p>案号：{item.caseNo}</p>
                    </Space>
                  </div>
                </Link>
              </Item>
            )
          }>
        </List>
      </div>
    </div>
  )
}

export default CaseList