import React, { useState, useEffect } from "react"
import _ from 'lodash'
import Lists from './Lists'
import style from './search.module.scss'

/* 
  搜索结果
 */
const SearchResults = (props) => {
  const { searchRes, goSearch, err } = props
  const [matchList, setMatchList] = useState()
  const [total, setTotal] = useState(0)

  useEffect(() => {
    let total = 0
    let list = []
    if (_.isArray(searchRes)) {
      list = searchRes
      total = searchRes.length
    }
    setMatchList(list)
    setTotal(total)
  }, [searchRes])

  return (
    err ? <p className={style.err}>{err}</p>
    : <div className={style.searchRes}>
      <p className={style.info}>为您匹配&nbsp;&nbsp;{total}&nbsp;&nbsp;项结果</p>
      <Lists lists={matchList} goSearch={goSearch} />
    </div>
  )
}

export default SearchResults