import React from "react"
import {Button, Avatar, Space} from "antd"
import { Link } from 'react-router-dom'

import style from "./header.module.scss"

/* 
  头部nav
 */
const Header = () => {
  const routers = [
    {
      title: '行业数据',
      path: '/'
    },
    {
      title: '司法关系图谱',
      path: '/judgesRelationships'
    },
    {
      title: '资产评估',
      path: '/assetsEvaluation'
    }
  ]

    return (
        <div className={style.header + ' clearfix'}>
            <div className={style.left}>
                <Space>
                    {
                        routers.map(r => (
                            <Link key={r.title} to={r.path}>
                                <Button type="text" className={r.title === '司法关系图谱' ? style.active : style.tab}>{r.title}</Button>
                            </Link>
                        ))
                    }
                </Space>
            </div>
            <div className={style.right}>
                <Space>
                    <Avatar size={50} src={process.env.PUBLIC_URL + "/assets/images/logo01.png"} />
                    <span className={style.company}>上海元沣网络科技有限公司</span>
                </Space>
            </div>
        </div>
    )
}

export default Header