import React, { useState, useEffect, useCallback } from "react"
import { Chart } from '@antv/g2';
import style from './style.module.scss'
import DateRangePicker from './DateRangePicker'

const id = 'bidPrice'

const DataView = (props) => {
  const {data, timer, dataType, getSingleData, titleName} = props
  const [chartData, setChartData] = useState(data)

  // 设置chart数据
  const getChartData = useCallback((params) => {
    if (params) { // 子元素改变时间
        getSingleData(params)
    }
    
    setChartData(data)
  }, [data, getSingleData])

  const changeTime = useCallback((date) => {
    document.getElementById(id).innerHTML = null
    getChartData({ part: 6, start: date[0], end: date[1], dataType })
  }, [getChartData, dataType])

  const renderChart = useCallback(() => {
    const chart = new Chart({
      container: id,
      autoFit: true,
      height: 500,
    });
    
    chart.data(chartData);
    chart.scale('num', {
      nice: true,
    });
    chart.legend({
      layout: 'vertical',
      position: 'top-right',
      items: [
        {
          name: 'x轴：成交金额（万元） ',
          value: '123',
          marker: {
            style: {
              fill: null
            }
          }
        },
        {
          name: 'y轴：成交套数（套）',
          value: '123',
          marker: {
            style: {
              fill: null
            }
          }
        }
      ]
    })
    chart.tooltip({
      shared: true,
      showMarkers: false,
    });
    
    chart
      .interval()
      .position('price*num')
      .color('type')
      .adjust('stack');
    
    chart.interaction('active-region');
    
    chart.render();
  },[chartData])

  useEffect(() => {
    document.getElementById(id).innerHTML = null
    if (data) {
      getChartData()
      renderChart()
    }
  }, [renderChart, timer, data, chartData, getChartData])
  return (
    <div className={style.box}>
      <div className={style.header}>
          <div className={style.left}>
              <div className={style.title}>{timer[0] + '-' + timer[1]}
              <span className={style.special}> {sessionStorage.getItem('cityName') ||'全国'} {sessionStorage.getItem('type')} </span>成交金额和{titleName}量关系分布
              </div>
          </div>
          <div className={style.right}>
              <DateRangePicker timer={timer} changeTime={(date) => changeTime(date)} />
          </div>
      </div>
      <div className={style.loop} id={id}></div>
  </div>
  )
}
export default DataView
