import React from "react"
import { Button,Divider } from "antd"
import { SettingFilled } from '@ant-design/icons';
import FilterMenu from "./FilterMenu"

import style from "./style.module.scss"
const Right = (props) => {
    const {noSelect = false, highlight = true} = props
    return (
        <>
            <div className={style.right} style={highlight ? {backgroundColor: 'rgb(242, 246, 255)'} : {}}>
                <div className={style.logoBox}>
                    <div className={style.info}>
                        <p>上海元沣科技网络有限公司</p>
                        <div>
                            <Button size="small" type="text" style={{ padding: '0px', fontSize: '12px' }} icon={<SettingFilled />}>设置</Button>
                            <span>老板</span>
                        </div>
                    </div>
                    <div className={style.logo}>
                        <img src="assets/images/logo01.png" alt="" />
                    </div>
                </div>
                    <Divider />
                    {
                        !noSelect && <FilterMenu {...props}></FilterMenu>
                    }
            </div>
        </>
    )
}

export default Right