import React from 'react'
import { Table } from 'antd'
import style from './history.module.scss'
const columns = [
  {
    title: '类型',
    dataIndex: 'type_title',
    key: 'type_title',
  },
  {
    title: '数量',
    dataIndex: 'show_cnt',
    key: 'show_cnt',
  },
  {
    title: '挂拍金额（亿元）',
    dataIndex: 'show_amount',
    key: 'show_amount',
  },
  {
    title: '成交金额（亿元）',
    key: 'deal_amount',
    dataIndex: 'deal_amount'
  }
];

const TypeData = (props) => {
  const { data } = props

  return (
    <div className={style.box}>
      <p className={style.title}>各类型</p>
      <div>
        {
          data ? <Table className={style.table} dataSource={data} columns={columns} rowKey="type_title" /> : ''
        }
      </div>
    </div>
  )
}

export default TypeData