import {
  actionTypes
} from "../actionTypes";

export const changeJCity = (state) => {
  return {
    type: actionTypes.CHANGEJCITY,
    state
  }
}

export const changePersonCount = (state) => {
  return {
    type: actionTypes.CHANGEPERSONCOUNT,
    state
  }
}
export const changeCaseCount = (state) => {
  return {
    type: actionTypes.CHANGECASECOUNT,
    state
  }
}
export const changeLocaitonCount = (state) => {
  return {
    type: actionTypes.CHANGELOCATIONCOUNT,
    state
  }
}