


function getItem (item) {
  return window.sessionStorage.getItem(item)
}

/* 
  name、pos、location分别表示当前的人名、职位、所在地
  subname、subpos、sublocation当前选中的合作人名、职位所在地
  如果选择的是法院或律所，则对应location为空，对应name为法院名（律所名），pos为法院（律所）
 */

export function getCurrentTarget () {
  const name = getItem('name')
  const pos = getItem('pos')
  const location = getItem('location')
  const subname = getItem('subname')
  const subpos = getItem('subpos')
  const sublocation = getItem('sublocation')
  return { name, pos, location, subname, subpos, sublocation }
}

export function changeCurrentTarget (name, pos, location, type) {
  switch (type) {
    case 1:
      window.sessionStorage.setItem('subname', name)
      window.sessionStorage.setItem('subpos', pos)
      window.sessionStorage.setItem('sublocation', location)
      break
    default:
      window.sessionStorage.setItem('name', name)
      window.sessionStorage.setItem('pos', pos)
      window.sessionStorage.setItem('location', location)
      break
  }
}