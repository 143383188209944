import React from 'react'
import Assets from '../components/assetsEvaluation'

const AssetsEvaluation = (props) => {
  return (
    <div style={{minWidth: '1440px'}}>
      <Assets />
    </div>
  )
}

export default AssetsEvaluation