import React, {useState, useEffect, useCallback} from 'react'
import _ from 'lodash'
import {Form, Input, Button, Carousel, Layout, Upload, message, Radio} from 'antd'
import axios from 'axios'
import ContactView from '../contact/index'
import {connect} from 'react-redux'
import style from './style.module.scss'
import './style.css'
import {PhoneOutlined, EyeInvisibleOutlined, LeftOutlined, RightOutlined, InboxOutlined, EnvironmentOutlined} from '@ant-design/icons'
import tryRequest from '../../utils/request'
import PayModal from './payModal'
const { Content, Sider} = Layout


const layout = {
    labelCol: {
        span: 2,
    },
    wrapperCol: {
        span: 24,
    },
};
const tailLayout = {
    wrapperCol: {
        span: 24,
    },
};

const carouselImgs = [  // 轮播图
    "http://static.yfbudong.com/horusbg1-min.png",
    "http://static.yfbudong.com/horusbg2-min.png",
    "http://static.yfbudong.com/horusbg3-min.png",
    "http://static.yfbudong.com/horusbg4-min.png",
    "http://static.yfbudong.com/horusbg5-min.png",
    "http://static.yfbudong.com/horusbg6-min.png"
]

const Register = (props) => {
    const {errMsg} = props
    const [cardStatus, setCardStatus] = useState(true);
    const [token, setToken] = useState();  // 七牛云token
    const [key, setKey] = useState(); // 七牛云的文件名
    const [process, setProcess] = useState(0) // 进度条
    const [imgUrl, setImgUrl] = useState('')  // 上传七牛云的名片链接
    const [price, setPrice] = useState('299') // 选中套餐价格
    const [phone, setPhone] = useState('')
    const [pwd, setPwd] = useState('123456')  // 默认密码123456
    const [showQr, setShowQr] = useState(false)
    const [sellId, setSellId] = useState(0)  // 套餐等级
    const [company, setCompany] = useState('')
    const [type, setType] = useState('1')  // 机构类型

    //提交注册
    const onFinish = (params) => {
        console.log(params);
        switch (params.price) {
            case '299':
                setSellId(1)
                break
            case '699':
                setSellId(2)
                break
            case '1299':
                setSellId(3)
                break
            default:
                break
        }
        setShowQr(true)
    };

    const hideQr = useCallback((pay) => {
        setShowQr(false)
    }, [])

    // 获取七牛云token，并设置key
    const makeUploadToken = useCallback(async () => {
        const res = await tryRequest({
            url: '/upload/token',
            method: 'get'
        })
        setToken(_.get(res, 'data.token') || '');
        setKey(Date.now() + Math.floor(Math.random()*(999999-100000)+100000)+1);
    }, [])

    useEffect(()=>{
        if(!token) makeUploadToken()
    },[makeUploadToken, token])

    function beforeUpload(file) {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("支持的图片格式：PNG、JPG、JPEG");
            return false;
        }
        const isLt2M = file.size / 1024 / 1024 < 20;
        if (!isLt2M) {
            message.error("图片大小需小于20MB!");
            return false;
        }
        if(!token) {
            message.error("上传网络异常，请重试");
            return false;
        }
        return true;
    }

    // 识别名片
    const recognizeCard = useCallback( async (url) => {
        if (url) {
            message.loading('正在识别...', 3)
            const res = await tryRequest({
                url: '/upload/recognize',
                method: 'post',
                data: {
                    url
                }
            })

            const data = _.get(res, 'data')
            if (data) {
                try {
                    const p = data.find(d => d.Name === '手机' || d.Name === '电话')
                    if (p) {
                        setPhone(p.Value || '')
                    }
                    const c = data.find(d => d.Name === '公司')
                    if (c) {
                        setCompany(c.Value || '')
                    }
                } catch (err) {
                    message.error(err)
                }
                setCardStatus(false)
            }
        }
    }, []) 

    const uploadProps = {
        name: 'file',
        showUploadList: false,
        multiple: false,
        accept: '.png, .jpg, .jpeg, .gif',
        action: 'http://upload.qiniu.com',
        beforeUpload: beforeUpload,
        onStart(file) {
            setImgUrl('')
            setProcess(0)
        },
        customRequest({  // 自定义上传请求
            action,
            file
        }) {
            let formData = new window.FormData()
            formData.append('file', file)
            formData.append('token', token)
            formData.append('key', key)
            // 创建一个干净的axios
            let $upload = axios.create({withCredentials: false});
            $upload({
                url: action,
                data: formData,
                method: 'POST',
                onUploadProgress: (progressEvent)=> {
                    //imgLoadPercent 是上传进度，可以用来添加进度条
                    let imgLoadPercent = Math.round(progressEvent.loaded * 100 / progressEvent.total);
                    setProcess(imgLoadPercent)
                },
            }).then((res)=>{
                const url = _.get(res, 'data.key')
                url && setImgUrl('http://static.yfbudong.com/'+url)
                recognizeCard('http://static.yfbudong.com/' + url)
            }).catch((err)=>{
                message.error("上传失败");
                debugger
                console.log(err);
            })
        }
    }

    //用户注册信息
    const InfoForm = ()=> (
        <Form {...layout} size="large" name="basicForm" onFinish={onFinish} className={style.registerForm}>
            {cardStatus ? <UploadForm /> : <UserInfo />}
            <Form.Item name="price" initialValue={price}>
                <Radio.Group buttonStyle="solid" value={price} onChange={e => setPrice(e.target.value)}>
                    <Radio.Button value="299">299元-1天</Radio.Button>
                    <Radio.Button value="699">699元-3天</Radio.Button>
                    <Radio.Button value="1299">1299元-7天</Radio.Button>
                    <ContactView isLink={true} showIcon={true} showIconColor={'#157ede'} text={'咨询'} />
                </Radio.Group>
            </Form.Item>
            <Form.Item name="type" initialValue={type}>
                <Radio.Group buttonStyle="solid" value={type} onChange={e => setType(e.target.value)}>
                    <Radio.Button value="1">金融机构</Radio.Button>
                    <Radio.Button value="2">处置服务商</Radio.Button>
                    <Radio.Button value="3">科研机构</Radio.Button>
                    <Radio.Button value="4">政府机关</Radio.Button>
                </Radio.Group>
            </Form.Item>
            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit" block>注册</Button>{errMsg ? <span>{errMsg}</span> : ''}
            </Form.Item>
        </Form>
    )

    // 失去焦点时获取数据，因为onChange时获取数据会失去焦点（不知道哪里引发渲染了）
    const UserInfo = ()=> (
        <div>
            <Form.Item labelAlign="left" name="phone" initialValue={phone} rules={[{required: true, message: '请输入手机号码！',},]}>
                <Input autoComplete={'on'} placeholder="请输入手机号码" onBlur={e => setPhone(e.target.value)} maxLength={20} prefix={<PhoneOutlined />}/>
            </Form.Item>
            <Form.Item labelAlign="left" name="company" initialValue={company} rules={[{required: true, message: '请输入公司名称！',},]}>
                <Input autoComplete={'on'} placeholder="请输入公司名称" onBlur={(e) => setCompany(e.target.value)} prefix={<EnvironmentOutlined />}/>
            </Form.Item>
            <Form.Item labelAlign="left" name="password" initialValue={pwd} rules={[{required: true, message: '请输入密码！',},]}>
                <Input.Password autoComplete={'on'} placeholder="请输入密码" onBlur={e => setPwd(e.target.value)} prefix={<EyeInvisibleOutlined />}/>
            </Form.Item>
        </div>
    )

    //用户上传
    const UploadForm = ()=> (
            <Form.Item name="dragger" valuePropName="fileList" noStyle>
                <Upload.Dragger {...uploadProps}>
                    {
                        process ? <span>上传进度：{process}%</span> : null
                    }
                    <p className="ant-upload-drag-icon">
                        {
                            imgUrl ? <img className={style.cardImg} src={imgUrl} alt="名片" /> : <InboxOutlined />
                        }
                    </p>
                    <p className="ant-upload-text">点击或拖拽上传名片</p>
                    <p className="ant-upload-hint">支持的图片格式：PNG、JPG、JPEG，暂不支持 GIF 格式</p>
                </Upload.Dragger>
            </Form.Item>
    )

    // 轮播图左右两侧箭头
    const SlickButtonFix = ({currentSlide, slideCount, children, ...props}) => (
        <span {...props}>{children}</span>
    )
    const leftOutlined = (
        <SlickButtonFix>
            <LeftOutlined />
        </SlickButtonFix>
    )
    const rightOutlined = (
        <SlickButtonFix>
            <RightOutlined />
        </SlickButtonFix>
    )
    

    return (
        <div className={style.registerIndex}>
            <Layout>
                <Content style={{ padding: '0 24px'}}>
                    <Carousel autoplay arrows  prevArrow={leftOutlined} nextArrow={rightOutlined}>
                        {
                            carouselImgs.map((c, i) => (
                                <div key={i}>
                                    <img src={c} alt={`荷鲁斯简介${i + 1}`} className={style.autoImg}/>
                                </div>
                            ))
                        }
                    </Carousel>
                </Content>
                <Sider width={500} style={{ padding: '0 24px'}}>
                    <p className={style.announce}>每个号码仅可试用一次，可体验基础策略，包括宏观数据、资产估值、司法关系图谱。
                        任何疑问，可联系<ContactView isLink={true} text={'咨询顾问'} /></p>
                    <InfoForm />
                    {
                        showQr ? <PayModal type={type} showQr={showQr} hideQr={hideQr} price={price} phone={phone} pwd={pwd} sellId={sellId} company={company} /> : null
                    }
                    
                </Sider>
            </Layout>
        </div>
    )
}

export default connect(
    state => ({errMsg: state.user.errMsg}),
    {}
)(Register)