import React, { useCallback, useState } from "react"
import { Button, Input, Select, Space, Popover, Row, Col, Checkbox, message } from "antd"
import { DownOutlined } from '@ant-design/icons';
import { connect } from 'react-redux'
import SearchResults from './SearchResults'
import HistoryList from './HistoryList'
import { city } from "../../../../static/mock/cityList.json"
import { changeJCity } from '../../../../redux/actions/judgeAction'
import style from "./search.module.scss"
const { Option } = Select
const { Group } = Checkbox

/**
 * 搜索页（司法关系图谱首页）
 */
const Search = (props) => {
  const [checkedlist, setCheckedlist] = useState(['judge', 'court', 'lawyer', 'lawfirm'])
  const { changeJCity, handleSearch, searchRes, city_id } = props
  const [input, setInput] = useState()
  const [err, setErr] = useState('')
  const [popVisible, setPopVisible] = useState(false)
  const [showHistory, setShowHistory] = useState(false)
  const [history, setHistory] = useState()

  // 选择城市
  const selectCity = useCallback((e) => {
    changeJCity(e)
  }, [changeJCity])

  // 点击搜索
  const goSearch = useCallback(() => {
    setShowHistory(false)
    if (input && input.trim()) {
      setErr('')
      handleSearch({input, options: checkedlist, city_id})
    } else {
      setErr('请输入正确的数据')
    }
  }, [input, handleSearch, checkedlist, city_id])

  // 弹出 条件设置
  const handleVisibleChange = useCallback((visible) => {
    setPopVisible(visible)
  }, [])

  // 选择 条件
  const onChange = list => {
    if (!list || !list.length) {
      setCheckedlist(checkedlist);
      message.warning('必须至少选一项')
    } else {
      setCheckedlist(list);
    }
  }

  // 搜索框聚焦，显示搜索历史
  const handleFocus = useCallback((e) => {
    e.stopPropagation();
    setShowHistory(true)
    let history = window.localStorage.getItem('s_history')
    if (!history) {
      history = []
    } else {
      history = JSON.parse(history)
    }
    setHistory(history)
  }, [])
  const handleBlur = useCallback(() => {
    setShowHistory(false)
  }, [])

  // 条件设置 弹框
  const options = () => {
    return (
      <div className={style.condition}>
        <p className={style.title}>条件设置</p>
        <Group value={checkedlist} style={{ width: '100%' }} onChange={onChange}>
          <Row>
            <Col span={6}>
              <Checkbox className={style.font18} value="judge">法官</Checkbox>
            </Col>
            <Col span={6}>
              <Checkbox className={style.font18} value="court">法院</Checkbox>
            </Col>
            <Col span={6}>
              <Checkbox className={style.font18} value="lawyer">律师</Checkbox>
            </Col>
            <Col span={6}>
              <Checkbox className={style.font18} value="lawfirm">律所</Checkbox>
            </Col>
          </Row>
        </Group>
        <p className={style.title}>城市选择</p>
        <div>
          <Select defaultValue="全国" onChange={selectCity} className={style.font18} bordered={false}>
            <Option value={null} key={null} className={style.font18}>{'全国'}</Option>
            {
              city.map(c => {
                return <Option value={c.city_id} key={c.city_id} className={style.font18}>{c.cityName}</Option>
              })
            }
          </Select>
        </div>
      </div>
    )
  }

  return (
    <div className={style.search} onClick={handleBlur}>
      <h3 className={style.title}>实时洞察关联关系</h3>
      <div className={style.searchWrapper}>
        <Space>
          <Input
            className={style.inputWrapper}
            placeholder='输入法官，律师，法院或律师事务所'
            onChange={e => setInput(e.target.value)}
            onPressEnter={goSearch}
            onClick={handleFocus}
            suffix={
              <Popover
                content={options}
                trigger="click"
                visible={popVisible}
                onVisibleChange={handleVisibleChange}
              >
                <span className={style.conditionBtn}>条件设置<DownOutlined /></span>
              </Popover>
            }
          />
          <Button type="primary" className={style.searchBtn} onClick={goSearch}>点击搜索</Button>
        </Space>
      </div>
      {
        showHistory ? <HistoryList history={history} goSearch={goSearch}/> : ''
      }
      {
        searchRes ? <SearchResults err={err} searchRes={searchRes} goSearch={goSearch}/> : ''
      }
    </div>
  )
}

export default connect(
  state => ({city_id: state.judge.city_id}),
  { changeJCity }
)(Search)