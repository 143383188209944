import React from 'react';
import { Chart } from '@antv/g2';
import style from "./style.module.scss"


const Loop = (props) => {
    const { id, type, percent, title, data} = props
    React.useEffect(() => {
        document.getElementById(id).innerHTML = null
        function apply_cnt(id, name, percent) {
            const chart = new Chart({
                container: id,
                autoFit: true,
                height:300,
            });
            chart.data(data);
            chart.scale(percent, {
                formatter: (val) => {
                    val = val * 100 + '%';
                    return val;
                },
            });
            chart.coordinate('theta', {
                radius: 0.75,
                innerRadius: 0.6,
            });
            chart.tooltip({
                showTitle: false,
                showMarkers: false,
                itemTpl: '<li class="g2-tooltip-list-item"><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>',
            });
            // 辅助文本
            chart
                .annotation()
                .text({
                    position: ['50%', '50%'],
                    content: title,
                    style: {
                        fontSize: 14,
                        fill: '#8c8c8c',
                        textAlign: 'center',
                    },
                    offsetY: -20,
                })
            chart
                .interval()
                .adjust('stack')
                .position(percent)
                .color(name)
                .label(percent, (percent) => {
                    return {
                        content: (data) => {
                            return `${data[type]}: ${(Math.round(percent * 10000) / 100).toFixed(2)}%`;
                        },
                    };
                })
                .tooltip(`${name}*${percent}`, (item, percent) => {
                    percent = `${(Math.round(percent * 10000) / 100).toFixed(2)}%`;;
                    return {
                        name: item,
                        value: percent,
                    };
                });

            chart.interaction('element-active');
            chart.render();
        }
        if (data) {
            apply_cnt(id, type, percent)
        }
    })
    return (
        <div className={style.box}>
            <div className={style.loop} id={id}></div>
        </div>
    );

}

export default Loop