import React, { useEffect, useCallback, useState } from "react"
import { Link,useLocation} from "react-router-dom"
import { connect } from 'react-redux'
import _ from 'lodash'
import tryRequest from "../../../utils/request"
import style from "./style.module.scss"
import Header from "./Header"
import Container from "./Container"
import { changeTime } from '../../../redux/actions/chartAction'

const Content = (props) => {
    const [data, setData] = useState();
    const [circData, setCircData] = useState();
    const [bidData, setBidData] = useState()
    const [courtData, setCourtData] = useState()
    const [historyData, setHistoryData] = useState()
    const { city_id, type_id, timer,changeTimer, timeList} = props
    const query = new URLSearchParams(useLocation().search)
    const dataType = parseInt(query.get('dataType')) || 0
    
    const getData = useCallback(async (url, params) => {
        const req_data = { city_id, type_id, start: timer[0], end: timer[1], timeList, part: -1 }
        const res = await tryRequest({
            url: '/data/' + url,
            method: 'POST',
            data: url === 'getHistoryData' ? { start: timer[0], end: timer[1] } : _.merge(req_data, params)
        })
        if (params) {
            return res.data
        }
        if (url === 'getCourtOrderList') {
            setCourtData(res.data)
        } else if (url === 'getCircData') {
            setCircData(res.data)
        } else if (url === 'getBidData') {
            setBidData(res.data)
        } else if (url === 'getHistoryData') {
            setHistoryData(res.data)
        } else {
            setData(res.data)
        }
    }, [city_id, type_id, timer, timeList])

    const getSingleData = useCallback((params) => {
        const part = params.part
        const partLists = ['moneyLine', 'typeChart', 'threeMap', 'moneyLine', 'bidCount', 'bidPrice', ['circSuccList', 'circFailList', 'circList'], ['circSuccRatio', 'circFailRatio', 'circRatio'], ['succList', 'failList', 'totalList']]
        const partKey = partLists[part - 1]
        let dataType = params.dataType || 0
        timeList[part - 1][dataType] = [params.start, params.end]
        let url = ''
        if (part < 5) {
            url = 'summation'
        } else if (part < 7) {
            url = 'getBidData'
        } else if (part < 9) {
            url = 'getCircData'
        } else {
            url = 'getCourtOrderList'
        }
        getData(url, params, part)
            .then(res_data => {
                if (url === 'summation') {
                    const data_ = JSON.parse(JSON.stringify(data))
                    data_[partKey + dataType] = res_data[partKey  + dataType] || res_data[partKey]
                    setData(data_)
                } else if (url === 'getBidData') {
                    const bidData_ = JSON.parse(JSON.stringify(bidData))
                    bidData_[partKey] = res_data[partKey]
                    setBidData(bidData_)
                } else if (url === 'getCircData'){
                    const circData_ = JSON.parse(JSON.stringify(circData))
                    circData_[partKey[dataType] + dataType] = res_data[partKey[dataType]]
                    setCircData(circData_)
                } else {
                    const courtData_ = JSON.parse(JSON.stringify(courtData))
                    courtData_[partKey[dataType] + dataType] = res_data[partKey[dataType]]
                    setCourtData(courtData_)
                }
            })
    }, [data, getData, bidData, circData, courtData, timeList])

    useEffect(() => {
        getData('summation');
        getData('getBidData')
        getData('getCircData')
        getData('getCourtOrderList')
        getData('getHistoryData')
    }, [getData])

    return (
        <div className={style.content}>
            <Header></Header>
            <div className={style.dataMenu}>
                <Link to='/?dataType=0' className={dataType===0||dataType===undefined?style.select:null}>全国成交数据 </Link>
                <Link to='/?dataType=1' className={dataType===1?style.select:null}>全国流拍数据</Link>
                <Link to='/?dataType=2' className={dataType===2?style.select:null}>全国挂拍数据</Link>
                <Link to='/?dataType=3' className={dataType===3?style.select:null}>城市数据汇总表</Link>
                <Link to='/?dataType=4' className={dataType===4?style.select:null}>行业历史数据</Link>
            </div>
            <Container
                data={data}
                bidData={bidData}
                historyData={historyData}
                circData={circData}
                courtData={courtData}
                timer={timer}
                changeTimer={changeTimer}
                getSingleData={getSingleData}
                timeList={timeList}></Container>
        </div>
    )
}

export default connect(
    state => ({timeList: state.chart.timeList}),
    {changeTime}
)(Content)
