import React, { useCallback, useEffect, useState } from 'react'
import { Avatar, Row, Col, Button } from 'antd'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import style from './detail.module.scss'

const InfoCard = (props) => {
  const { subname='', subpos='', subLocation='', location, personCount, caseCount, locationCount, totalLocation = 0, totalPerson = 0, totalCase = 0, isList = false } = props
  const [name, setName] = useState('')
  const [pos, setPos] = useState('')
  const [title, setTitle] = useState('')

  // 获取基本信息
  const query = new URLSearchParams(useLocation().search)
  let name_ = query.get('name') || ''
  let pos_ = query.get('pos') || ''
  let title_ = query.get('location') || location || ''
  if (!name_) {
    name_ = window.sessionStorage.getItem('name') || ''
    pos_ = window.sessionStorage.getItem('pos') || ''
    title_ = window.sessionStorage.getItem('location') || location || ''
  }

  useEffect(() => {
    if (subname || name_) {
      setName(subname || name_)
      setPos(subpos || pos_)
      setTitle(subLocation || title_)
    }
  }, [name_, subname, pos_, subpos, title_, subLocation])

  // 渲染右侧
  const RenderRight = useCallback(() => {
    switch (pos) {
      case '法官':
        return (
          <div className={style.rightInfo}>
            <Row className={style.line}>共{isList ? '直接' : ''}合作律师<span className={[style.highlight, style.red].join(' ')}>{totalPerson || personCount}</span>名</Row>
            <Row className={style.line}>共{isList ? '直接' : ''}合作律所<span className={[style.highlight, style.red].join(' ')}>{totalLocation || locationCount}</span>家</Row>
            <Row className={style.line}>共{isList ? '直接' : ''}参与案件<span className={[style.highlight, style.red].join(' ')}>{totalCase || caseCount}</span>件</Row>
          </div>
        )
      case '法院':
        return (
          <div className={style.rightInfo}>
            <Row className={style.line}>共参与案件<span className={[style.highlight, style.red].join(' ')}>{caseCount}</span>件</Row>
          </div>
        )
      case '律所':
        return (
          <div className={style.rightInfo}>
            <Row className={style.line}>共参与案件<span className={[style.highlight, style.green].join(' ')}>{caseCount}</span>件</Row>
          </div>
        )
      case '律师':
        return (
          <div className={style.rightInfo}>
            <Row className={style.line}>共{isList ? '直接' : ''}合作法官<span className={[style.highlight, style.green].join(' ')}>{totalPerson || personCount}</span>名</Row>
            <Row className={style.line}>共{isList ? '直接' : ''}参与案件<span className={[style.highlight, style.green].join(' ')}>{totalCase || caseCount}</span>件</Row>
          </div>
        )
      default:
        return null
    }
  }, [pos, caseCount, personCount, locationCount, totalCase, totalLocation, totalPerson, isList])

  // 渲染左侧
  const RenderLeft = useCallback(() => {
    if (pos === '律师' || pos === '法官') {
      return (
        <div className={style.personBox}>
          <div className={style.title}>
            <div className={style.name}>{name}</div>
            <div className={pos.indexOf('法') > -1 ? [style.pos, style.judge].join(' ') : [style.pos, style.lawyer].join(' ')}>{pos}</div>
          </div>
          <div className={style.location}>{title}</div>
        </div>
      )
    } else {
      return (
        <div className={style.locationBox}>
          <div className={style.title}>
            <div className={style.name}>{name}</div>
            <div className={style.posLine}><Button className={pos.indexOf('法') > -1 ? [style.pos, style.judge].join(' ') : [style.pos, style.lawyer].join(' ')}>{pos}</Button></div>
          </div>
        </div>
      )
    }
  }, [name, pos, title])

  return (
    <div className={style.card}>
      {
        title ? <Avatar size={77} src="/assets/images/avatar.png" className={style.avatar} /> : ''
      }
      <Row className={style.infoWrapper}>
        <Col span={14} className={style.left}>
          <RenderLeft />
        </Col>
        <Col span={10} className={style.right}>
          <RenderRight />
        </Col>
      </Row>
    </div>
  )
}

export default connect(
  state => ({caseCount: state.judge.caseCount, personCount: state.judge.personCount, locationCount: state.judge.locationCount})
)(InfoCard)