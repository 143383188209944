import React, { useEffect, useCallback, useState } from "react"
import tryRequest from "../utils/request"
import BidCount from "../components/dataviews/BidCount"

const Test = (props) => {
    const [data, setData] = useState({});
    const [city_id] = useState()
    const [type_id] = useState();
    const [circData, setCircData] = useState();
    const [bidData, setBidData] = useState()
    const [timer, setTimer] = useState(['2020-01','2020-12']);
    const changeTimer = useCallback(async (value) => { setTimer(value) },[])

    const getData = useCallback(async (url, params) => {
      const req_data = { city_id, type_id, start: timer[0], end: timer[1], part: -1 }
      const res = await tryRequest({
          url: '/data/' + url,
          method: 'POST',
          data: params ? Object.assign(req_data, params) : req_data
      })
      if (params) {
          return res.data
      }
      if (url === 'getCircData') {
          setCircData(res.data)
      } else if (url === 'getBidData') {
          setBidData(res.data)
      } else {
          setData(res.data)
      }
  }, [city_id, type_id, timer])
  const getSingleData = useCallback((params) => {
      const part = params.part
      const partLists = ['moneyLine', 'typeChart', 'threeMap', 'proportionType', 'bidCount', 'bidPrice', 'circList', 'circRatio']
      const partKey = partLists[part - 1]
      const dataType = params.dataType || 0
      const url = part === -1 || part  < 5 ? 'summation' : part < 7 ? 'getBidData' : 'getCircData'
      getData(url, params)
          .then(res_data => {
              
              if (url === 'summation') {
                  const data_ = JSON.parse(JSON.stringify(data))
                  data_[partKey + dataType] = res_data[partKey]
                  setData(data_)
              } else if (url === 'getBidData') {
                  const bidData_ = JSON.parse(JSON.stringify(bidData))
                  bidData_[partKey + dataType] = res_data[partKey]
                  setBidData(bidData_)
              } else {
                  const circData_ = JSON.parse(JSON.stringify(circData))
                  circData_[partKey + dataType] = res_data[partKey]
                  setCircData(circData_)
              }
          })
  }, [data, getData, bidData, circData])

    useEffect(() => {
      getData('getBidData')
    }, [getData])

    if (bidData) {
      return (
        <div>
          <BidCount
              changeTimer={changeTimer}
              timer={timer}
              dataType={0}
              titleName={'成交'}
              data={bidData ? bidData['bidCount0'] || bidData.bidCount : []}
              getSingleData={getSingleData}></BidCount>
        </div>
      )
    } else {
      return null
    }
}

export default Test




/* import React, {useState, useEffect, useCallback} from "react"
// import { Chart } from '@antv/g2';
// import CountBar from '../components/dataviews/CountBar'
import CountRate from '../components/dataviews/CountRate'
// import DataSet from '@antv/data-set'
// import style from '../components/dataviews/style.module.scss'
// import DateRangePicker from '../components/dataviews/DateRangePicker'
// import tryRequest from '../utils/request'

// const id = 'circleChart'
// const timer = ['2020-01','2020-12']
// const dataType = 0
// const data_ = [
//   { State: '一拍', type1: 0, type2: 0, type3: 0 },
//   { State: '二拍', type1: 0, type2: 0, type3: 0 },
//   { State: '变卖', type1: 0, type2: 0, type3: 0 }
// ];

const Test = (props) => {
//   const [date, setDate] = useState(timer)
//   const [chartData] = useState(data_)

//   const renderChart = useCallback(() => {
//     const ds = new DataSet();
//     const dv = ds.createView().source(chartData);
//     dv.transform({
//       type: 'fold',
//       fields: ['type1', 'type2', 'type3'], // 展开字段集
//       key: '年龄段', // key字段
//       value: '人口数量', // value字段
//       retains: ['State'], // 保留字段集，默认为除fields以外的所有字段
//     });

//     const chart = new Chart({
//       container: id,
//       autoFit: true,
//       height: 500,
//     });

//     chart.coordinate().transpose();

//     chart.data(dv.rows);
//     chart.scale('人口数量', { nice: true });

//     chart.axis('State', {
//       label: {
//         offset: 12,
//       },
//     });

//     chart.tooltip({
//       shared: true,
//       showMarkers: false,
//     });

//     chart
//       .interval()
//       .adjust('stack')
//       .position('State*人口数量')
//       .color('年龄段');

//     chart.interaction('active-region');

//     chart.render();
//   },[chartData])

//   const getChartData = useCallback((params) => {
    
//     tryRequest.

//       if (params) { // 子元素改变时间
//           setDate([params.part_start, params.part_end])
//       } else {  // 父元素改变时间
//       }
//       renderChart()
//   }, [chartData, renderChart])

//   const changeTime = useCallback((date) => {
//     setDate(date)
//     getChartData({ part: 2, part_start: date[0], part_end: date[1], dataType })
//   }, [getChartData])


//   useEffect(() => {
//     renderChart()
//   }, [renderChart, chartData, getChartData])
  return (
    <div>
      <h1>hhhh</h1>
      <CountRate></CountRate>
    </div>
//     <div className={style.box}>
//       <div className={style.header}>
//           <div className={style.left}>
//               <div className={style.title}>{date[0] + '-' + date[1]}
//               <span className={style.special}> {sessionStorage.getItem('cityName') ||'全国'} {sessionStorage.getItem('type')} </span>累计成交量
//               </div>
//           </div>
//           <div className={style.right}>
//               <DateRangePicker timer={date} changeTime={(date) => changeTime(date)} />
//           </div>
//       </div>
//       <div className={style.loop} id={id}></div>
//   </div>
  )
}
export default Test */