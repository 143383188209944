import { combineReducers } from 'redux'
import user from './userReducer'
import chart from './chartReducer'
import judge from './judgeReducer'
import assets from './assetsReducer'

const reducers = combineReducers({
    user,
    chart,
    judge,
    assets
})

export default reducers;