import React from 'react'
import DetailPanel from '../DetailPanel'
import { Tabs } from 'antd'
import style from './judge.module.scss'
const { TabPane } = Tabs;

/* 
  法院详情
 */
const JudgeDetail = (props) => {
  const {data, location, name, pos, caseTypes, lawFirms, lawFirmData, pCaseTypes, partnerData} = props
  return (
    <div className={style.contentWrapper}>
      <Tabs className={style.content} defaultActiveKey="1" type="card" tabBarGutter={64} tabBarStyle={{fontSize: '28px', padding: '68px 0 52px'}}>
        <TabPane className={style.pane} tab="合作律师" key="1">
          <DetailPanel location={location} title='合作律师汇总' subpos='律师' data={data} name={name} pos={pos} caseTypes={caseTypes} locations={lawFirms}/>
        </TabPane>
        <TabPane className={style.pane} tab="合作律师事务所" key="2">
          <DetailPanel location={location} title='合作律师事务所汇总' subpos='律所' data={lawFirmData} name={name} pos={pos} caseTypes={caseTypes} locations={lawFirms}/>
        </TabPane>
        <TabPane className={style.pane} tab="合作同事" key="3">
          <DetailPanel location={location} title='合作同事汇总' subpos='同事' data={partnerData} name={name} pos={pos} caseTypes={pCaseTypes} locations={lawFirms}/>
        </TabPane>
      </Tabs>
    </div>
  )
}

export default JudgeDetail