import React, { useCallback, useState } from "react"
import _ from 'lodash'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import Header from './Header'
import tryRequest from '../../utils/request'
import style from './style.module.scss'

/* 
    司法关系图谱 首页
        默认重定向到search子路由
 */
const JudgesRelationships = (props) => {
    const { routes } = props
    const path = useLocation().pathname
    const [searchRes, setSearchRes] = useState()

    // 搜索数据
    const getData = useCallback(async (url, params) => {
        const res = await tryRequest({
          url: '/law' + url,
          method: 'POST',
          data: params
        })
        return res.data
    }, [])
    const handleSearch = useCallback((params) => {
        
        getData('/search', params)
            .then(jres => {
                const list = _.get(jres, 'paramRec')
                setSearchRes(list)
            })
    }, [getData])


    return (
        <div className={path === '/judgesRelationships/search' ? style.image : style.blue}>
            <Header />
            <Switch>
                {
                    routes.map(route => (
                        <Route
                            path={route.path}
                            key={route.path}
                            render={props => (
                                <route.component
                                    searchRes={searchRes}
                                    handleSearch={handleSearch}
                                    component={route.component}
                                    routes={route.children} 
                                />
                            )}
                        />
                    ))
                }
                <Redirect to='/judgesRelationships/search' />
            </Switch>
        </div>
    )
}
export default JudgesRelationships