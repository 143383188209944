import {
  applyMiddleware,
  createStore
} from "redux";
import thunkMiddleware from "redux-thunk";

import rootReducer from "./reducer/index";

// const bindMiddleware = middleware => {
//   // if (process.env.NODE_ENV !== "production") {
//   //   const {
//   //     composeWithDevTools
//   //   } = require("redux-devtools-extension");
//   //   // 开发模式打印redux信息
//   //   const {
//   //     logger
//   //   } = require("redux-logger");
//   //   middleware.push(logger);
//   //   return composeWithDevTools(applyMiddleware(...middleware));
//   // }
//   return applyMiddleware(...middleware);
// };

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));


export default store