import React, { useEffect, useState } from 'react'
import {Row, Col, Table} from 'antd'
import _ from 'lodash'
import PriceTrend from './PriceTrend'
import style from './result.module.scss'
import comstyle from '../../home/Content/header.module.scss'

/* 
  预测结果
 */
const AssetResults = (props) => {
  const { data } = props
  const [predictPrice, setPredictPrice] = useState(0)
  const [err, setError] = useState(0)
  const [success, setSuccess] = useState([])
  const [market, setMarket] = useState([])
  const [goingArr, setGoingArr] = useState([])
  const [successArr, setSuccessArr] = useState([])
  const [lostArr, setLostArr] = useState([])
  const [otherArr, setOtherArr] = useState([])
  const [similarArr, setSimilarArr] = useState([])
  const [housePics, setHousePics] = useState([])
  const [chartData, setChartData] = useState()

  // table列
  const tableColumms = [
    {
      title: '结束时间',
      dataIndex: 'end_time',
      key: 'end_time',
    },
    {
      title: '标的物名称(点击跳转)',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => text ? <a href={record.ann_url} target='_blank' rel="noopener noreferrer">{text}</a> : ''
    },
    {
      title: '当前价',
      dataIndex: 'currentPrice',
      key: 'currentPrice',
      render: text => text ? (text/10000).toFixed(2) + '万' : '--'
    },
    {
      title: '评估价',
      dataIndex: 'consultPrice',
      key: 'consultPrice',
      render: text => text ? (text/10000).toFixed(2) + '万' : '--'
    },
    {
      title: '当前单价',
      dataIndex: 'per_price',
      key: 'per_price',
      render: text => text ? (text/10000).toFixed(2) + '万/平米' : '--'
    },
    {
      title: '当前折扣',
      dataIndex: 'court_discount',
      key: 'court_discount',
      render: text => text ? (text * 10).toFixed(2) + '折' : '--'
    },
    {
      title: '面积',
      dataIndex: 'area',
      key: 'area',
    },
    {
      title: '年代',
      dataIndex: 'year',
      key: 'year',
      render: text => text || '--'
    },
    {
      title: '小区(点击跳转)',
      dataIndex: 'community_name',
      key: 'community_name',
      render: (text, record) => text ? <a href={record.community_url} target='_blank' rel="noopener noreferrer">{text}</a> : ''
    },
    {
      title: '拍卖轮次',
      dataIndex: 'circ',
      key: 'circ',
    },
    {
      title: '数据来源',
      dataIndex: 'datafrom_cn',
      key: 'datafrom_cn',
    }
  ]

  const tableSimliarColumms = tableColumms.concat([{
    title: '成交状态',
    dataIndex: 'cn_status',
    key: 'cn_status',
  }]);

  useEffect(() => {
    if (data) {
      const { predictPrice,predictRate,successAnalysis, marketAnalysis, successArr = [], goingArr = [], lostArr = [], otherArr = [], similarArr = [], marKetInfo = {} } = data
      setPredictPrice(predictPrice || '-')
      setError(predictRate)  // 准确率在[75, 90]之间
      const succArr = []
      const marketArr = []
      _.isArray(successAnalysis) && successAnalysis.map(a => succArr.push(a.desc))
      setSuccess(succArr)
      _.isArray(marketAnalysis) && marketAnalysis.map(m => marketArr.push(m.desc))
      setMarket(marketArr)
      setGoingArr(goingArr)
      setSuccessArr(successArr)
      setLostArr(lostArr)
      setOtherArr(otherArr)
      setSimilarArr(similarArr)
      const { housePics, quoteTrend} = marKetInfo
      quoteTrend && setChartData(quoteTrend)
      housePics && setHousePics(housePics)
    }
  }, [data])

  return (
    <div className={style.resultWrapper}>
      <div className={style.desc}>
        <p className={style.title}>评估结果</p>
        <button className={[comstyle.primaryBtn, comstyle.selected, style.evaluation].join(' ')}>
          <Row className={style.wrapper}>
            <Col span={8}>
              <img className={style.icon} src='assets/images/predict_icon.png' alt="predict icon" />
            </Col>
            <Col span={8}>
              <p><span className={style.fontLarge}>{parseInt(predictPrice)}</span>&nbsp;元/㎡</p>
              <p>资产评估价格</p>
            </Col>
            <Col span={8}>
              <p><span className={style.fontLarge}>{err}</span>%</p>
              <p>准确率</p>
            </Col>
          </Row>
        </button>
        <p className={style.title}>同类法拍成交数据分析：</p>
        {
          success.length && success.map((s, i) => (
            <p key={i} className={style.descPara}>{s}</p>
          ))
        }
        <p className={style.title}>同类二手房数据分析：</p>
        {
          market.length && market.map((m, i) => (
            <p key={i} className={style.descPara}>{m}</p>
          ))
        }
      </div>
      <p className={style.title}>正在拍卖</p>
      {
        goingArr.length ? <Table dataSource={goingArr} columns={tableColumms} rowKey='id' pagination={false} /> : '暂无数据'
      }
      <p className={style.title}>成交数据</p>
      {
        successArr.length ? <Table dataSource={successArr} columns={tableColumms} rowKey='id' pagination={false} /> : '暂无数据'
      }
      <p className={style.title}>流拍数据</p>
      {
        lostArr.length ? <Table dataSource={lostArr} columns={tableColumms} rowKey='id' pagination={false} /> : '暂无数据'
      }
      <p className={style.title}>撤/缓拍数据</p>
      {
        otherArr.length ? <Table dataSource={otherArr} columns={tableColumms} rowKey='id' pagination={false} /> : '暂无数据'
      }
      <p className={style.title}>相似法拍参考</p>
      {
        similarArr.length ? <Table dataSource={similarArr} columns={tableSimliarColumms} rowKey='id' pagination={false} /> : '暂无数据'
      }
      <p className={style.title}>小区图片</p>
      {
        housePics.length ? <Row className={style.housePics} gutter={20}>
          {
            housePics.map(item => (
              <Col span={4} key={item}>
                <img className={style.image} src={item} alt="house pic" />
              </Col>
            ))
          }
        </Row> : null
      }
      <p className={style.title}>二手房走势图</p>
      <PriceTrend data={chartData} />
    </div>
  )
}

export default AssetResults
