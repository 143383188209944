import React, { useState } from 'react'
import { Dropdown, Menu } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons';
import _ from 'lodash'
import style from './detail.module.scss'
const { Item } = Menu

/* 
  下拉菜单
 */
const SelectBar = (props) => {
  const { navMenu, selectItem, caseTypes, locations } = props
  const [relationshipTypes] = useState([
    {
      id: 1,
      title: '直接关系',
      checked: false
    },
    {
      id: 2,
      title: '间接关系',
      checked: false
    }
  ])

  // 下拉菜单选项
  const menu = (menuProps) => {
    const {selectItem, type, index } = menuProps
    let list = []
    switch (type) {
      case 'relationshipTypes':
        list = relationshipTypes || []
        break
      case 'caseTypes':
        list = caseTypes || []
        break
      case 'locations':
        list = locations || []
        break
      default:
        list = []
        break
    }
    const handleSelect = (l, i) => {
      _.map(list, item => item.checked = item.id === l.id ? !item.checked : false)
      selectItem(l, i)
    }
    return (
      <Menu className={style.menuList}>
        {
          list.length && list.map(l => (
            <Item className={l.checked ? style.menuItemActive : ''} onClick={() => handleSelect(l, index)} key={l.id}>{l.title}</Item>
          ))
        }
      </Menu>
    );
  }

  return (
    <div className={style.navMenu}>
      {
        navMenu.map((n, i) => (
          <Dropdown overlay={() => menu({type: n.list, selectItem, index: i})} key={n.title}>
            <div className={n.title !== n.default ? style.navMenuItem + ' ' + style.navMenuItemActive: style.navMenuItem}><span className={style.textOverOne}>{n.title} </span><CaretDownOutlined /></div>
          </Dropdown>
        ))
      }
    </div>
  )
}

export default SelectBar