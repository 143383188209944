import React from "react"
import JRelationships from "../components/judgesRelationships"

const JudgesRelationships = (props) => {
    return (
        <div>
            <JRelationships {...props}></JRelationships>
        </div>
    )
}
export default JudgesRelationships