import React from "react"
import {Button, Popover} from 'antd'
import {CustomerServiceTwoTone} from '@ant-design/icons';
import style from './style.module.scss'

const concatContent = (
    <div>
        <img className={style.concatImg} src="http://static.yfbudong.com/contacthorus-min.png" alt="客服联系方式"/>
    </div>
);

const View = (props) => {
    const {isLink, text, showIcon, showIconColor} = props
    return (
        isLink ?
        <Popover content={concatContent} title="商务部负责人" trigger="click">
            <Button type="link" htmlType="button">
                {showIcon ? <CustomerServiceTwoTone twoToneColor={showIconColor || '#00c033'}/>: null}
                {text}
            </Button>
        </Popover> :
        <Popover className={style.mt10} content={concatContent} title="商务部负责人">
            <CustomerServiceTwoTone twoToneColor="#00c033"/>
            <p>{text}</p>
        </Popover>
    )

}

export default View
