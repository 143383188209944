import {
  actionTypes
} from "../actionTypes";

const t = []
for (let i =0; i< 9; i++) {
    t[i] = []
    for (let j = 0; j < 3; j++) {
        t[i][j] = ['2020-01', '2020-12']
    }
}

const initState = {
  timeList: t
};

function reducer(state = initState, action) {
  switch (action.type) {
      case actionTypes.CHANGETIME:
          return {
              ...state,
              timeList: action.timeList
          };
      default:
          return state;
  }
}

export default reducer;