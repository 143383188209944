import axios from "axios"
import { message } from 'antd';
import Cookies from 'js-cookie'

let env_index = 1;

export const request = axios.create({
  baseURL: ['http://localhost:4321', 'http://116.62.240.91:4321'][env_index]
})

// 请求头携带token
// 所有请求必携带参数：userId_, uuid (为了多账号登录被踢)
request.interceptors.request.use(function (config) {
  config.headers.Authorization = 'Bearer ' + Cookies.get('token')
  const uuid_ = window.localStorage.getItem('uuid') || ''
  const userId_ = window.localStorage.getItem('userId') || ''
  if (config.method === "post") {
    config.data.userId_ = userId_
    config.data.uuid_ = uuid_
  } else if (config.method === 'get') {
    config.url += config.url.indexOf('?') > -1 ? '&' : '?'
    config.url += `userId_=${userId_}&uuid_=${uuid_}`
  }
  return config;
}, function(err) {
  return Promise.reject(err)
});

// axios拦截器
async function tryRequest(obj) {
  try {
    return await request(obj)
  } catch (error) {
    debugger
    if (error.response && error.response.data && error.response.data.msg) {
      const msg = error.response.data.msg
      message.error(msg);
      if (msg.indexOf("该操作需登录进行") > -1) { // 刷新后（无token）会自动重定向到login
        Cookies.remove('token')
        window.location.reload()
      }
    }
    throw error
  }
}

export default tryRequest

