import {
  actionTypes
} from "../actionTypes";


const initState = {
  city_id: null,
  cityName: ''
};

function reducer(state = initState, action) {
  switch (action.type) {
      case actionTypes.CHANGEACITY:
          return {
              ...state,
              city_id: action.state,
              cityName: action.cityName
          };
      default:
          return state;
  }
}

export default reducer;