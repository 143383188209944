import React from 'react'
import { Popover, Button } from 'antd'
import style from './style.module.scss'

const ConcatPop = (props) => {
  const { text } = props

  const concatContent = (
    <div>
        <img className={style.concatImg} src="http://static.yfbudong.com/horuscontact.png" alt="客服联系方式"/>
    </div>
  );

  return (
    <span>
      <Popover className={style.mt10} content={concatContent} title="商务部负责人" trigger="click">
          <Button type="link" htmlType="button">
              {text}
          </Button>
      </Popover>
    </span>
  )
}

export default ConcatPop