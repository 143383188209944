import React, { useCallback } from 'react'
import { Modal, Button } from 'antd'
import ConcatPop from './concatPop'

const PayModal = (props) => {
  const { errCode, errMsg, hideModal } = props
  const handleCancle = useCallback(() => {
    hideModal()
  }, [hideModal])

  return (
    <div>
      <Modal 
      visible={errCode === '1004'}
      onCancel={handleCancle}
      maskClosable={true}
      footer={[
        <Button key={1} type='link' onClick={handleCancle}>不想用了</Button>,
        <ConcatPop key={2} text={'继续用'} />
      ]}
      >
        <div>
          <p>{errMsg}</p>
        </div>
      </Modal>
    </div>
  )
}

export default PayModal