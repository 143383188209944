import React, { useState, useCallback } from 'react';
import { Chart } from '@antv/g2';
import style from "./style.module.scss"
import formatNum from '../../utils/formatNum'
import DateRangePicker from './DateRangePicker'

const DataView = (props) => {
    const [total, setTotal] = React.useState(0)
    const { data, id, title, titleName, timer, dataType, getSingleData } = props
    const [chartData, setChartData] = useState(data)

    // 设置chart数据
    const getChartData = useCallback((params) => {
        if (params) { // 子元素改变时间
            getSingleData(params)
        }
        setChartData(data)
    }, [data, getSingleData])

    const changeTime = useCallback((date) => {
        document.getElementById(id).innerHTML = null
        getChartData({ part: 2, start: date[0], end: date[1], dataType })
    }, [getChartData, dataType, id])

    const renderChart = useCallback(() => {
        const chart = new Chart({
            container: id,
            autoFit: true,
            height: 500,
        });

        chart.data(chartData);
        chart.scale(title, {
            nice: true,
        });
        chart.tooltip({
            showMarkers: false,
            shared: true,
        });
        chart.legend({
            position: 'bottom',
        })

        chart
            .interval()
            .position(`month*${title}`)
            .color('type')
            .label(title)
            .adjust([
                {
                    type: 'dodge',
                    marginRatio: 0,
                },
            ]);

        chart.interaction('active-region');

        chart.render();
    }, [chartData, id, title])

    React.useEffect(() => {
        document.getElementById(id).innerHTML = null
        setTotal(0)
        if (data) {
            getChartData()
            chartData.forEach((item, i) => {
                item[title] = parseFloat(item[title])
                item.type = item.type_id === 1 ? '住宅' : item.type_id === 2 ? '商业' : '工业'
                setTotal(total => total + parseFloat(item[title]))
            })
            renderChart()
        }
    }, [data,id,title,renderChart,timer,chartData,getChartData])
    
    return (
        <div className={style.box}>
            <div className={style.header}>
                <div className={style.left}>
                    <div className={style.title}>{timer[0] + '-' + timer[1]}
                    <span className={style.special}> {sessionStorage.getItem('cityName') ||'全国'} {sessionStorage.getItem('type')} </span>累计{titleName}量
                    </div>
                    <div className={style.data}>{formatNum(total)}</div>
                </div>
                <div className={style.right}>
                    <DateRangePicker timer={timer} changeTime={(date) => changeTime(date)} />
                </div>
            </div>
            <div id={id}></div>
        </div>
    );

}

export default DataView
