import React, { useEffect } from 'react'
import { Table } from 'antd'
import style from './history.module.scss'
const columns = [
  {
    title: '省份',
    dataIndex: 'province_name',
    key: 'province_name',
  },
  {
    title: '城市数',
    dataIndex: 'city_num',
    key: 'city_num',
  },
  {
    title: '挂拍金额（亿元）',
    dataIndex: 'show_amount',
    key: 'show_amount',
  },
  {
    title: '成交金额（亿元）',
    key: 'deal_amount',
    dataIndex: 'deal_amount'
  },
  {
    title: '挂拍量（套）',
    dataIndex: 'show_cnt',
    key: 'show_cnt',
    sorter: (a, b) => a.show_cnt - b.show_cnt,
  },
   {
    title: '成交量（套）',
    dataIndex: 'deal_cnt',
    key: 'deal_cnt',
  },
  {
    title: '数量成交率',
    dataIndex: 'cnt_ratio',
    key: 'cnt_ratio'
  },
  {
    title: '金额成交率',
    dataIndex: 'amount_ratio',
    key: 'amount_ratio'
  },
];

const ProvinceData = (props) => {
  const { data } = props

  useEffect(() => {
    if (data) {
      data.map(d => {
        d.cnt_ratio = (d.deal_cnt / d.show_cnt).toFixed(2) + '%'
        d.amount_ratio = (d.deal_amount.replace(',','') / d.show_amount.replace(',','')).toFixed(2) + '%'
        return d
      })
    }
  }, [data])

  return (
    <div className={[style.box, style.noBottomMargin].join(' ')}>
      <p className={style.title}>全国各省份交易情况分析</p>
      <div>
        {
          data ? <Table className={style.table} dataSource={data} columns={columns} rowKey="province_name" /> : ''
        }
      </div>
    </div>
  )
}

export default ProvinceData