import React from "react"
import Routers from './route/index'
import store from "./redux/store"
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd';
import locale from 'antd/es/locale/zh_CN';
import 'moment/locale/zh-cn';
  
const Home = () => {
    return (
        <Provider store={store}>
            <ConfigProvider locale={locale}>
                <Routers></Routers>
            </ConfigProvider>
        </Provider>
    )
}

export default Home
