import React, { useState, useEffect, useCallback } from "react"
import { Chart } from '@antv/g2';
import style from './style.module.scss'
import DateRangePicker from './DateRangePicker'
const id = 'countRate'

const DataView = (props) => {
  const {data, timer, dataType, getSingleData, titleName} = props
  const [chartData, setChartData] = useState(data)

  // 设置chart数据
  const getChartData = useCallback((params) => {
    if (params) { // 子元素改变时间
        getSingleData(params)
    }
    
    setChartData(data)
  }, [data, getSingleData])

  const changeTime = useCallback((date) => {
      document.getElementById(id).innerHTML = null
      getChartData({ part: 8, start: date[0], end: date[1], dataType })
  }, [getChartData, dataType])
  
  const renderChart = useCallback(() => {
    const chart = new Chart({
      container: id,
      autoFit: true,
      height: 500
    });
    chart.data(chartData);
    chart.legend(false);
    chart.tooltip({
      showMarkers: false
    });
    chart.facet('rect', {
      fields: ['circ'],
      padding: 20,
      showTitle: false,
      eachView: (view, facet) => {
        const data = facet.data;
        let color;
        if (data[0].circ === '一拍') {
          color = '#0a9afe';
        } else {
          color = '#f0657d';
        }
        data.push({ circ: '其他', ratio: 100 - data[0].ratio });
        view.data(data);
        view.coordinate('theta', {
          radius: 0.8,
          innerRadius: 0.5
        });
        view
          .interval()
          .adjust('stack')
          .position('ratio')
          .color('circ', [color, '#eceef1'])
          .style({
            opacity: 1,
          });
        view.annotation().text({
          position: [ '50%', '50%' ],
          content: data[0].circ + titleName + '率',
          style: {
            fontSize: 12,
            fill: '#8c8c8c',
            fontWeight: 300,
            textBaseline: 'bottom',
            textAlign: 'center'
          },
          offsetY: -12,
        });
    
        view.annotation().text({
          position: ['50%', '50%'],
          content: data[0].ratio + '%',
          style: {
            fontSize: 18,
            fill: '#000',
            fontWeight: 500,
            textAlign: 'center'
          },
          offsetY: 10,
        });
    
        view.interaction('element-active');
      }
    });
    chart.render();
  }, [chartData, titleName])

  useEffect(() => {
    document.getElementById(id).innerHTML = null
    if (data) {
      getChartData()
      renderChart()
    }
  }, [renderChart, timer, data, chartData, getChartData])
  return (
    <div className={style.box}>
      <div className={style.header}>
          <div className={style.left}>
              <div className={style.title}>{timer[0] + '-' + timer[1]}
              <span className={style.special}> {sessionStorage.getItem('cityName') ||'全国'} {sessionStorage.getItem('type')} </span>各拍卖阶段{titleName}率情况
              </div>
          </div>
          <div className={style.right}>
              <DateRangePicker timer={timer} changeTime={(date) => changeTime(date)} />
          </div>
      </div>
      <div className={style.loop} id={id}></div>
  </div>
  )
}

export default DataView