import React from 'react'
import UserLogin from '../components/login'

const Login = (props) => {

  return (
    <div>
      <UserLogin {...props} />
    </div>
  )
}

export default Login