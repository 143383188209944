import React, { useEffect, useState, useCallback } from "react"
import { Chart } from '@antv/g2';
import style from "./style.module.scss"
import formatNum from '../../utils/formatNum'
import DateRangePicker from './DateRangePicker'

const DataView = (props) => {
    const [total, setTotal] = React.useState(0)
    const { data, timer, dataType, getSingleData } = props
    const [chartData, setChartData] = useState(data)

    // 设置chart数据
    const getChartData = useCallback((params) => {
        if (params) { // 子元素改变时间
            getSingleData(params)
        }
        setChartData(data)
    }, [data, getSingleData])

    const changeTime = useCallback((date) => {
        getChartData({ part: 4, start: date[0], end: date[1], dataType: dataType })
    }, [getChartData, dataType])

    const renderChart = useCallback(() => {
        const chart = new Chart({
            container: 'AmountLine',
            autoFit: true,
            height: 500,
        });
        chart.data(chartData);
        chart.scale({
            month: {
                range: [0, 1]
            },
            apply_cnt: {
                alias: '参与竞拍竞拍总人数',
                nice: true,
            },

        });
        chart.axis('apply_cnt');
        chart.legend({
            position: 'bottom',
        });
        chart.tooltip({
            showCrosshairs: true,
            showTitle: false,
            itemTpl:
                '<li style="list-style: none;padding-bottom:10px">' +
                '<span style="background-color:{color};" class="g2-tooltip-marker"></span>' +
                '{name}<br/>' +
                '<span style="padding-left: 16px">参与竞拍人数：{count}</span><br/>' +
                '</li>',
        });
        chart
            .line()
            .position('month*apply_cnt')
            .color('#4FAAEB')
            .label('apply_cnt')
            .shape('timer', timer => {
                if (timer === '去年同期数据') {
                    return 'dash'
                }
            })
            .tooltip('month*apply_cnt', (name, count) => {
                return {
                    name,
                    count,
                };
            });
        chart.render();
    }, [chartData])

    useEffect(() => {
        setTotal(0)
        document.getElementById('AmountLine').innerHTML = null
        if (data) {
            getChartData()

            chartData.forEach(element => {
                element.apply_cnt = parseInt(element.apply_cnt)
                if (element.timer !== '去年同期数据') {
                    setTotal(total => total + parseInt(element.apply_cnt))
                }
            });
            renderChart()
        }
    }, [data, chartData, getChartData, renderChart, timer])

    return (
        <div className={style.box}>
            <div className={style.header}>
                <div className={style.left}>
                    <div className={style.title}>{timer[0] + '-' + timer[1]}
                        <span className={style.special}> {sessionStorage.getItem('cityName') ||'全国'} {sessionStorage.getItem('type')}</span> 司法拍卖报名参与者人数
                    </div>
                    <div className={style.data}>{formatNum(total)}</div>
                </div>
                <div className={style.right}>
                    <DateRangePicker timer={timer} changeTime={(date) => changeTime(date)} />
                </div>
            </div>
            <div id="AmountLine"></div>
        </div>
    )
}

export default DataView