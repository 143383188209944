import React from "react"
import { useLocation } from 'react-router-dom'
import MoneyLine from "../../dataviews/MoneyLine"
import Chart from "../../dataviews/Chart"
import ThreeMap from "../../dataviews/ThreeMap"
import FailThreeMap from "../../dataviews/FailThreeMap"
import Loop from "../../dataviews/Loop"
import AmountLine from "../../dataviews/AmountLine"
import CityData from "../../dataviews/CityData"
import CircleChart from '../../dataviews/CircleChart'
import CourtOrder from '../../dataviews/CourtOrder'
import CircRatio from '../../dataviews/CircRatio'
import BidCount from '../../dataviews/BidCount'
import BidPrice from '../../dataviews/BidPrice'
import HistoryData from '../../dataviews/HistoryData/index'

import style from "./Container.module.scss"
const DataView = (props) => {
    const [width, setWidth] = React.useState(null)
    const { data, changeTimer, getSingleData, bidData, circData, courtData, timeList, historyData } = props
    const query = new URLSearchParams(useLocation().search)
    const dataType = parseInt(query.get('dataType')) || 0
    const handleResize = React.useCallback(() => {
        let e_width = window.innerWidth * 0.5 > 500 ? window.innerWidth * 0.5 : 500;
        setWidth(e_width)
    }, [])
    React.useEffect(() => {
        window.addEventListener('resize', handleResize) //监听窗口大小改变

        return () => { window.removeEventListener('resize', handleResize) }
    }, [handleResize])

    function showViews() {
        if (data || bidData || circData || courtData || historyData) {
            const titleName = ['成交', '流拍', '挂拍'][dataType]
            const moneyTitle = ['sum_price', 'fail_consult', 'consult'][dataType]
            const chartTitle = ['done_cnt', 'fail_cnt', 'cnt'][dataType]
            const courtTitle = ['succ', 'fail', 'total'][dataType]
            const percent1 = ['achieve_ratio', 'fail_ratio', 'cnt_ratio'][dataType]
            const percent2 = ['sum_price_ratio', 'fail_consult_ratio', 'cnt_consult_ratio'][dataType]
            const court = ['succList', 'failList', 'totalList'][dataType]
            const circList = ['circSuccList', 'circFailList', 'circList'][dataType]
            const circRatio = ['circSuccRatio', 'circFailRatio', 'circRatio'][dataType]
            if (dataType === 3)
                if (data && data.cityData)
                    return <CityData changeTimer={changeTimer} data={data.cityData} />;
                else
                    return '暂无数据'
            else if (dataType === 4)
                if (historyData)
                    return <HistoryData changeTimer={changeTimer} data={historyData} />;
                else
                    return '暂无数据'
            else
                return (
                    <div >
                        <MoneyLine
                            id='line1'
                            title={moneyTitle}
                            titleName={titleName}
                            timer={timeList[0][dataType]}
                            dataType={dataType}
                            data={data ? data['moneyLine' + dataType] || data.moneyLine : []}
                            getSingleData={getSingleData}>
                        </MoneyLine>
                        <Chart
                            id='char1'
                            title={chartTitle}
                            titleName={titleName}
                            timer={timeList[1][dataType]}
                            dataType={dataType}
                            data={data ? data['typeChart' + dataType] || data.typeChart : []}
                            getSingleData={getSingleData}>
                        </Chart>
                        {
                            dataType === 0
                            ? <div>
                                <ThreeMap
                                    width={width}
                                    timer={timeList[2][0]}
                                    dataType={dataType}
                                    data={data ? data['threeMap0'] || data.threeMap : []}
                                    getSingleData={getSingleData}></ThreeMap>
                                <AmountLine
                                    timer={timeList[3][0]}
                                    dataType={dataType}
                                    data={data ? data['moneyLine00'] || data.moneyLine : []}
                                    getSingleData={getSingleData}></AmountLine>
                                <BidCount
                                    timer={timeList[4][0]}
                                    dataType={dataType}
                                    titleName={titleName}
                                    data={bidData ? bidData['bidCount0'] || bidData.bidCount : []}
                                    getSingleData={getSingleData}></BidCount>
                                <BidPrice
                                    timer={timeList[5][0]}
                                    dataType={dataType}
                                    titleName={titleName}
                                    data={bidData ? bidData['bidPrice0'] || bidData.bidPrice : []}
                                    getSingleData={getSingleData}></BidPrice>
                            </div>
                            : dataType === 1
                            ? <FailThreeMap
                                timer={timeList[2][1]}
                                dataType={dataType}
                                data={data ? data['threeMap1'] || data.threeMap : []}
                                getSingleData={getSingleData}></FailThreeMap>
                            : null
                        }
                        <CircleChart
                            timer={timeList[6][dataType]}
                            dataType={dataType}
                            titleName={titleName}
                            data={circData ? circData[circList+dataType] || circData[circList] : []}
                            getSingleData={getSingleData}></CircleChart>
                        <div className={style.loopBox}>
                            <Loop id={'loop1'} type={'typeName'} percent={percent1} title={titleName + '量占比'} data={data ? data.proportionType : []}></Loop>
                            <Loop id={'loop2'} type={'typeName'} percent={percent2} title={titleName + '额占比'} data={data ? data.proportionType : []}></Loop>
                        </div>
                        <CircRatio
                            timer={timeList[7][dataType]}
                            dataType={dataType}
                            titleName={titleName}
                            data={circData ? circData[circRatio+dataType] || circData[circRatio] : []}
                            getSingleData={getSingleData}></CircRatio>
                        <CourtOrder
                            timer={timeList[8][dataType]}
                            title={courtTitle}
                            dataType={dataType}
                            titleName={titleName}
                            data={courtData ? courtData[court+dataType] || courtData[court] : []}
                            getSingleData={getSingleData}></CourtOrder>
                    </div>
                )
        }
        return '暂无数据'
    }

    return (
        <div >
            {showViews()}
        </div>
    )
}

export default DataView