import React, { useState, useEffect, useCallback } from 'react'
import md5 from 'md5'
import { Modal, Radio, Button } from 'antd'
import { useHistory } from 'react-router-dom'
import QRCode from 'qrcode.react'
import style from './style.module.scss'
import tryRequest from '../../utils/request'
import ConcatPop from '../contact'
let timer = null

/* 
注册支付弹框
 */
const PayModal = (props) => {
  
  const history = useHistory()
  const {showQr, hideQr, price, phone, sellId, pwd, company, type } = props
  const [qrcode, setQrcode] = useState('')
  const [channel, setChannel] = useState('alipay')  // 支付渠道，默认支付宝
  const [success, setSuccess] = useState(false)
  
  // 跳转登录页面，同时清除轮询定时器
  const goLogin = useCallback(() => {
    history.push('/login')
    clearInterval(timer)
    timer = null
  }, [history])

  // 轮询，查看用户是否注册（扫码成功）
  const longPoling = useCallback(async () => {
    const res = await tryRequest({
      method: 'POST',
      url: '/user/checkRegister',
      data: {
        phone
      }
    })
    const { code } = res.data
    if (code === 1) {
      setSuccess(true)
      setTimeout(() => {
        goLogin()
      }, 3000)
    }
  }, [phone, goLogin])

  // 获取二维码
  const getQr = useCallback(async() => {
    
    const res = await tryRequest({
      method: 'POST',
      url: '/pay/member',
      data: {
        body: '荷鲁斯注册',
        price,
        sellId,
        phone,
        type,
        password: md5(pwd),
        channel,
        company
      }
    })
    if (res && res.data) {
      setQrcode(res.data)
      clearInterval(timer)
      timer = setInterval(() => {
        longPoling()
      }, 2000)
    }
  }, [longPoling, price, sellId, phone, pwd, type, channel, company])


  const changeRadio = useCallback(e => {
    setChannel(e.target.value)
  }, [])

  useEffect(() => {
    if (showQr) {
      setSuccess(false)
    }
    if (price && phone && channel && company) {
      getQr()
    }
  }, [price, showQr, getQr, phone, channel, company])


  return (
    <Modal visible={showQr} onCancel={hideQr}>
      <div className={style.payModal}>
        <p className={style.title}>选择支付方式：
          {
            success ? <span className={style.success}>&nbsp;&nbsp;注册成功，3s后跳转登录页面</span> : null
          }
        </p>
        <div className={style.ratioWrapper}>
          <Radio.Group value={channel} onChange={changeRadio}>
            <Radio value="alipay">支付宝</Radio>
            <Radio value="wx">微信</Radio>
          </Radio.Group>
        </div>
        <div className={style.qrcode}>
          {
            qrcode ? <QRCode value={qrcode} /> : <p>请上传名片</p>
          }
        </div>
        <div className={style.consult}>
          <ConcatPop text="支付成功未跳转？" isLink={true} showIconColor='true' showIcon='true' />
          <Button type="link"  onClick={goLogin} >点我跳转登录页面</Button>
        </div>
      </div>
    </Modal>
  )
}

export default PayModal