import React, { useState, useEffect, useCallback } from "react"
import { useLocation } from 'react-router-dom'
import _ from 'lodash'
import tryRequest from '../../../../../utils/request'
import style from './case.module.scss'

/* 
  案件详情
 */
const CaseDetail = (props) => {
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const query = new URLSearchParams(useLocation().search)
  const caseNo = query.get('caseNo') || 0
  
  // 格式化文本（分段）
  const ContentHTML = useCallback(() => {
    if (!content) return null
    const arr = content.split('\n')

    return (
      <div>
        {
          arr.map((item, i) => (
            <p key={i}>{item}</p>
          ))
        }
      </div>
    )
  }, [content])

  // 获取数据
  const getData = useCallback(async () => {
    const res = await tryRequest({
      url: '/law/caseDetail',
      method: 'POST',
      data: {caseNo}
    })
    const caseContent = _.get(res, 'data.ret[0]')
    const {content} = caseContent
    caseContent.title && setTitle(caseContent.title)
    content && setContent(content)
  }, [caseNo])

  useEffect(() => {
    getData()
  }, [getData])

  return (
    <div className={style.caseDetail}>
      <h3 className={style.title}>{title}</h3>
      <div className={style.content}>
        <ContentHTML />
      </div>
    </div>
  )
}

export default CaseDetail