import React, { useEffect, useState, useCallback } from "react"
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import _ from 'lodash'
import { message } from 'antd'
import { connect } from "react-redux"
import Card from '../Card'
import style from './case.module.scss'
import tryRequest from "../../../../../utils/request"
import {getCurrentTarget} from "../../../../../utils/changeCurrentTarget"
import { changeCaseCount, changeLocaitonCount, changePersonCount} from '../../../../../redux/actions/judgeAction'

/**
 * 案件首页（默认跳转案件列表页）
 */
const Cases = (props) => {
  const { routes, changeCaseCount, changeLocaitonCount, changePersonCount } = props

  const query = new URLSearchParams(useLocation().search)
  const locationCount = query.get('locationCount') || 0
  const caseCount = query.get('caseCount') || 0
  const personCount = query.get('personCount') || 0
  const pcourt_id = query.get('pcourt_id') || 0
  const [data, setData] = useState()
  const [caseTypes, setCaseTypes] = useState([])
  const { name = '', pos = '', location = '', subname = '', subpos = '', sublocation = ''} = getCurrentTarget()

  // 获取数据
  const getData = useCallback(async (params) => {
    const hide = message.loading('正在获取数据...', 0)
    const res = await tryRequest({
      url: '/law/cases',
      method: 'POST',
      data: { name, pos, location, subname, subpos, sublocation, pcourt_id }
    })
    hide()
    const ret = res.data.ret
    const {case_types} = ret
    if (_.isArray(case_types)) {
      const types = []
      case_types.map((c, i) => types.push({id: i, title: c, checked: false}))
      setCaseTypes(types)
    }
    setData(ret)
  }, [name, pos, location, subname, subpos, sublocation, pcourt_id])

  useEffect(() => {
    changeLocaitonCount(locationCount)
    changeCaseCount(caseCount)
    changePersonCount(personCount)
    if (name && subname)
      getData()
  }, [name, subname, getData, changeLocaitonCount, changeCaseCount, changePersonCount, caseCount, personCount, locationCount])

  return (
    <div className={style.detailWrapper}>
      <Card name={name} title={location} pos={pos} />
      <div className={style.pane}>
        <Switch>
            {
              routes.map(route => (
                <Route
                  path={route.path}
                  key={route.path}
                  render={props => (
                    <route.component
                      caseCount={caseCount}
                      locationCount={locationCount}
                      personCount={personCount}
                      data={data}
                      name={name}
                      pos={pos}
                      subname={subname}
                      subpos={subpos}
                      sublocation={sublocation}
                      caseTypes={caseTypes}
                      component={route.component}
                      routes={route.children} 
                    />
                  )}
                />
              ))
            }
            <Redirect to='/judgesRelationships/cases/list' />
        </Switch>
      </div>
    </div>
  )
}

export default connect(
  state => ({}),
  {changeCaseCount, changePersonCount, changeLocaitonCount}
)(Cases)