import React, { useEffect, useCallback, useState } from 'react'
import { Chart } from '@antv/g2';
import style from './history.module.scss'

const YearRatioChart = (props) => {
  const { data, id } = props
  const [year, setYear] = useState()

  const renderChart = useCallback(() => {
    const chart = new Chart({
      container: id,
      autoFit: true,
      height: 400,
    });
    
    chart.data(data);
    chart.coordinate('theta', {
      radius: 0.75,
      innerRadius: 0.6,
    });
    chart.tooltip({
      showTitle: false,
      showMarkers: false,
    });
    chart.legend({
      position: 'top',
      flipPage: false
    });
    chart
      .interval()
      .adjust('stack')
      .position('percent')
      .color('type')
      .shape('slice-shape');
    
    chart.render();    
  }, [data, id])

  useEffect(() => {
    if (data) {
      const {period} = data[0] || {}
      period && setYear(period)
      document.getElementById(id).innerHTML = null
      renderChart()
    }
  }, [data, renderChart, id, setYear])

  return (
    <div className={style.yearRatio}>
      <div id={id}></div>
      <p className={style.title}>{year}年</p>
    </div>
  )
}

export default YearRatioChart