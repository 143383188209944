import React, { useCallback, useState } from "react"
import { DatePicker} from 'antd';
import moment from 'moment'
import Cookies from 'js-cookie'
import { useHistory } from 'react-router-dom'
const { RangePicker } = DatePicker;
const formatDate = 'YYYY-MM'

const DateRangePicker = (props) => {
  const history = useHistory();
  const { timer, changeTime } = props
  const [month, setMonth] = useState([])

  const disabledDate = current => {
    if (!month || month.length === 0) {
        return false;
    }
    const tooLate = month[0] && current.diff(month[0], 'months') > 11;
    const tooEarly = month[1] && month[1].diff(current, 'months') > 11;
    return tooEarly || tooLate;
};

  const changeDate = useCallback((value, d) => {
      changeTime(d)
  }, [changeTime])

  // 未登录不能选时间
  const handleOpen = (open) => {
    if (open && !Cookies.get('token')) {
      history.push({
        pathname: '/login',
        state: { from: history.location.pathname }
      })
    }
  }
  
  return (
    <div>
      <RangePicker
        onOpenChange={handleOpen}
        value={[moment(timer[0], formatDate), moment(timer[1], formatDate)]}
        disabledDate={disabledDate}
        // className={style.timeBox}
        picker="month"
        onChange={changeDate}
        onCalendarChange={value => {
            setMonth(value);
        }} />
    </div>
  )
}

export default DateRangePicker