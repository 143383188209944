import React, { useState, useCallback } from "react"
import { Table, Input, Button, DatePicker, message } from 'antd';
import Cookies from 'js-cookie'
import { useHistory } from 'react-router-dom'
import XLSX from "xlsx"
import style from "./style.module.scss"

const { RangePicker } = DatePicker;

const columns = [
    {
        title: '城市名称',
        dataIndex: 'cityName',
        render: text => <span onClick={e => { console.log(e) }}>{text}</span>,
    },
    {
        title: '省份',
        dataIndex: 'provinceName',
    },
    {
        title: '拍卖数量',
        dataIndex: 'cnt',
        sorter: (a, b) => a.cnt - b.cnt,
    },
    {
        title: '成交数量',
        dataIndex: 'done_cnt',
        sorter: (a, b) => a.done_cnt - b.done_cnt,
    },
    {
        title: '挂拍总额（万）',
        dataIndex: 'consult',
        sorter: (a, b) => a.consult - b.consult,
    },
    {
        title: '总交易额（万）',
        dataIndex: 'sum_price',
        sorter: (a, b) => a.sum_price - b.sum_price,
    },
    {
        title: '平均折扣率',
        dataIndex: 'discount',
        sorter: (a, b) => a.discount - b.discount,
    },
    {
        title: '成交率(%)',
        dataIndex: 'done_ratio',
        sorter: (a, b) => a.done_ratio - b.done_ratio,
    },
    {
        title: '流拍率(%)',
        dataIndex: 'fail_ratio',
        sorter: (a, b) => a.fail_ratio - b.fail_ratio,
    },
    {
        title: '成交均价（万）',
        dataIndex: 'per_price',
        sorter: (a, b) => a.per_price - b.per_price,
    },
    {
        title: '平均报名人数',
        dataIndex: 'apply_avg',
        sorter: (a, b) => a.apply_avg - b.apply_avg,
    },
    {
        title: '平均出价次数',
        dataIndex: 'bid_avg',
        sorter: (a, b) => a.bid_avg - b.bid_avg,
    },
    {
        title: '平均浏览次数',
        dataIndex: 'viewer_avg',
        sorter: (a, b) => a.viewer_avg - b.viewer_avg,
    },
    {
        title: '月上拍套数',
        dataIndex: 'month_avg',
        sorter: (a, b) => a.month_avg - b.month_avg,
    },
];


const CityData = (props) => {
    const history = useHistory();
    const [newData, setNewData] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const { data, changeTimer } = props
    const inputCity = useCallback(e => {
        const value = e.target.value.toLocaleUpperCase()
        const newCities = data.filter(item => {
            return item.cityName.includes(value) || item.citySpell.includes(value)
        })
        setNewData(newCities)
    }, [data, setNewData])
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows)
        },
        getCheckboxProps: record => ({
            name: record.name,
        }),
    };
    const changeTime = useCallback((value, d) => {
        changeTimer(d)
    }, [changeTimer])
    const handleOpen = (open) => {
        if (open && !Cookies.get('token')) {
            history.push({
                pathname: '/login',
                state: { from: history.location.pathname }
            })
        }
      }
    // 下载当页数据
    const downloadXlsx = useCallback(() => {
        const data = document.getElementsByTagName('table')[0]
        var wb = XLSX.utils.table_to_book(data, {
            sheet: "SheetJS"
        });
        return XLSX.writeFile(wb, '城市汇总数据.xlsx');
    }, [])
    // 下载选中数据
    const downloadSelected = useCallback(() => {
        if (selectedRows.length > 0) {
            const selectedData = []
            selectedData[0] = columns.map(item => item.title)
            selectedRows.forEach(item => {
                const arr = []
                columns.forEach(ele => {
                    arr.push(item[ele.dataIndex])
                })
                selectedData.push(arr)
            })
            const sheet = XLSX.utils.aoa_to_sheet(selectedData);
            var new_workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(new_workbook, sheet, "SheetJS");
            XLSX.writeFile(new_workbook,'城市汇总数据.xlsx')
            return
        }
        message.warn('请最少选择一项数据')
    }, [selectedRows])
    return (
        <div className={style.box}>
            <div className={style.filterBox}>
                <Input className={style.timeBox} onChange={inputCity} placeholder="输入城市搜索" />
                <RangePicker
                    onOpenChange={handleOpen}
                    className={style.timeBox}
                    picker="month"
                    onChange={changeTime}
                />
                <Button className={style.timeBox} onClick={downloadSelected}>下载选中数据</Button>
                <Button className={style.timeBox} onClick={downloadXlsx}>下载当页数据</Button>
            </div>
            <Table
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}
                columns={columns}
                pagination={
                    { onShowSizeChange: (c, z) => { console.log(c, z) } }
                }
                dataSource={newData.length === 0 ? data : newData}
            />
        </div>
    )
}

export default CityData