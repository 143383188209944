import {
    actionTypes
} from "../actionTypes";

const exampleInitialState = {
    userInfo: {},
    errMsg: '',
    errCode: ''
};

function reducer(state = exampleInitialState, action) {
    switch (action.type) {
        case actionTypes.LOGIN:
            return {
                ...state,
                errMsg: '',
                userInfo: action.state,
                isLogin: true
            };
        case actionTypes.LOGINFAILED:
            return {
                ...state,
                ...action.state
            }
        case actionTypes.SETLOGINSTATE:
            return {
                ...state,
                isLogin: action.state
            }
        case actionTypes.REGISTER:
            return {
                ...state,
                errMsg: '',
            }
        case actionTypes.REGISTERFAIL:
            return {
                ...state,
                errMsg: action.state
            }
        case actionTypes.UPLOAD:
            return {
                ...state,
                errMsg: action.state
            }
        case actionTypes.CLEARERRMSG:
            return {
                ...state,
                errMsg: '',
                errCode: ''
            }
        default:
            return state;
    }
}

export default reducer;