import DataSet from '@antv/data-set';
import { Chart } from '@antv/g2';
import React from "react"
import style from "./style.module.scss"

const { DataView } = DataSet;

const FailThreeMap = (props) => {
    const { data } = props
    React.useEffect(() => {
        document.getElementById('threeMap').innerHTML = null
        data.forEach(element => {
            element.value = Math.floor(element.fail_consult) / 10000;
        });
        data.sort((a,b)=>{
            return b.value-a.value
        })
        const _data = {
            name: 'root',
            children: data
        };
        const dv = new DataView();
        dv.source(_data, {
            type: 'hierarchy',
        }).transform({
            field: 'value',
            type: 'hierarchy.treemap',
            tile: 'treemapResquarify',
            as: ['x', 'y'],
        });

        // 将 DataSet 处理后的结果转换为 G2 接受的数据
        const nodes = [];
        for (const node of dv.getAllNodes()) {
            if (node.data.name === 'root') {
                continue;
            }
            const eachNode = {
                name: node.data.cityName || node.data.zoneName,
                x: node.x,
                y: node.y,
                value: node.data.value,
                discount: node.data.fail_ratio,
            };

            nodes.push(eachNode);
        }
        // const chart = canvasMap()
        document.getElementById('threeMap').innerHTML = null
        let chart = new Chart({
            container: 'threeMap',
            autoFit: true,
            // width: 1500,
            height: 500,
        });
        chart.coordinate().scale(1, -1);
        chart.data(nodes);

        chart.axis(false);
        chart.legend(false);
        chart.tooltip({
            showTitle: false,
            showMarkers: false,
            itemTpl:
                '<li style="list-style: none;padding-bottom:10px">' +
                '<span style="background-color:{color};" class="g2-tooltip-marker"></span>' +
                '{name}<br/>' +
                '<span style="padding-left: 16px">流拍额：{count}/万元</span><br/>' +
                '<span style="padding-left: 16px">流拍率：{discount}%</span><br/>' +
                '</li>',
        });
        chart
            .polygon()
            .position('x*y')
            .color('discount', (discount) => {
                if (discount > 50) { return '#8e2f35' }
                else if (discount > 45) { return '#e44249' }
                else if (discount > 40) { return '#555356' }
                else if (discount >= 35) { return '#246323' }
                else if (discount >= 30) { return '#36882c' }
                else { return '#3dc53d' }
            })
            .tooltip('name*value*discount', (name, count, discount) => {
                return {
                    name,
                    count,
                    discount
                };
            })
            .style({
                lineWidth: 1,
                stroke: '#fff'
            })
            .label('name*value*discount', {
                offset: 0,
                style: {
                    textBaseline: 'middle',
                },
                content: (obj, c, index) => {
                    if (obj.name !== 'root') {
                        const width = obj.x[1] - obj.x[0]
                        const height = obj.y[1] - obj.y[2]
                        if (index === 0)
                            return `${obj.name}\n 流拍额:${obj.value}万元\n流拍率:${obj.discount}%`;
                        if (width * height > 0.015)
                            return `${obj.name}\n ${obj.value}万元\n${obj.discount}%`
                        return obj.name
                    }
                },
            });
        chart.interaction('element-active');
        chart.render();
        return () => { chart = null; }
    }, [data])
    return (
        <div style={{ background: '#fff', marginBottom: '20px' }}>
            <div className={style.box} style={{ overflow: 'auto' }}>
                <div id="threeMap"></div>
            </div>
            <div className={style.threeText}>
                <p>地图面积越大代表这个区的流拍量越大；颜色越深，流拍率越高</p>
                <div className={style.threeLegend} style={{ width: '100%' }}></div>
            </div>
        </div>
    )
}

export default FailThreeMap