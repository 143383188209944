import React, { useCallback } from 'react'
import { List, Pagination, Button } from 'antd'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import style from './panel.module.scss'
import Card from './Card'
const {Item} = List

/* 
  法院下所有法官列表
  律所下所有律师列表
 */
const ListPanle = (props) => {
  const { subpos, data, name, getCurrent, total = 5, type } = props

  // 渲染分页item
  const itemRender = useCallback((current, type, originalElement) => {
    if (type === 'prev') {
      return <Button>上一页</Button>;
    }
    if (type === 'next') {
      return <Button>下一页</Button>;
    }
    return originalElement;
  },[])

  return (
    <div className={style.panel}>
      <div className={style.header + ' clearfix'}>
        <h3 className={style.title}>{subpos}名单</h3>
      </div>
      <div>
        <List split={false}>
          {
            _.isArray(data) && data.map(item => (
              <Item key={item.name}>
                <Link to={`/judgesRelationships/detail/${type}?name=${item.name}&pos=${subpos}&location=${name}`}  className={style.cardItem} target='_blank'>
                  <Card isList={true} subname={item.name} subpos={subpos} subLocation={name} totalPerson={item.personCount} totalCase={item.caseCount} totalLocation={item.locationCount} />
                </Link>
              </Item>
            ))
          }
        </List>
        
        {
          total && <Pagination total={total} onChange={getCurrent} pageSizeOptions={[10, 20]} itemRender={itemRender} />
        }
      </div>
    </div>
  )
}

export default ListPanle