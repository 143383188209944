import md5 from 'md5'
import _ from 'lodash'
import Cookies from 'js-cookie'
import { message } from 'antd'
import {
  actionTypes
} from "../actionTypes";
import tryRequest from '../../utils/request'

// 登录
export const setInfo = (state) => {
  return async dispatch => {
    const {username, password, status} = state
    if (username && password && status) {
      const res = await tryRequest({
        url: '/user/login',
        method: 'POST',
        data: { username, password: md5(password), status}
      })
      const { errMsg, errCode,  token, user_name, company_id, user_id, update_time, uuid } = _.get(res, 'data') || {}
      if (token && user_name && user_id) {
        Cookies.set('token', token, { expires: new Date(update_time)})
        message.success('登录成功！')
        window.localStorage.setItem('userId', user_id)
        window.localStorage.setItem('username', user_name)
        window.localStorage.setItem('uuid', uuid)
        return dispatch({
          type: actionTypes.LOGIN,
          state: {username: user_name, company_id, userId: user_id }
        })
      }
      else
        return dispatch({
          type: actionTypes.LOGINFAILED,
          state: { errMsg, errCode }
        })
    } else {
      return dispatch({
        type: actionTypes.LOGINFAILED,
        state: '请输入用户名和密码！'
      })
    }
  }
}

//注册
export const registerUser = (state) =>{
  return async dispatch => {
      return dispatch({
        type: actionTypes.REGISTER,
        state: {content: 'success'}
      })
  }
}

export const clearErrMsg = () => ({
  type: actionTypes.CLEARERRMSG
})

export const setLoginState = (state) => ({
  type: actionTypes.SETLOGINSTATE,
  state
})