import React, { useCallback, useState, useEffect } from 'react'
import _ from 'lodash'
import BaseInfo from './BaseInfo'
import style from './panel.module.scss'
import PaneHeader from './PaneHeader'


/* 
  详情面板
 */
const DetailPanel = (props) => {
  const initNav = [
    {title: '关系类型', default: '关系类型', list: 'relationshipTypes'},
    {title: '案件类型', default: '案件类型', list: 'caseTypes'},
    {title: '律师事务所', default: '律师事务所', list: 'locations'}
  ]
  const { location, name, pos, data, caseTypes, locations, title, subpos } = props
  
  const [renderList, setRenderList] = useState()  // 渲染数据
  const [relationshipType, setRelationshipType] = useState()  // 当前关系类型
  const [caseType, setCaseType] = useState()  // 当前案件类型
  const [lawFirm, setlawFirm] = useState()  // 当前律所
  const [court, setCourt] = useState()  // 当前法院
  const [type, setType] = useState()  // 当前nav类型：judge | lawyer | lawFirm
  if (pos === '律师') {
    initNav[2] = {title: '法院', default: '法院', list: 'locations'}
  }
  const [navMenu, setnavMenu] = useState(initNav) // nav
  
  
  // selectBar选择条件
  const selectItem = useCallback((item, index) => {

    switch (index) {
      case 0:
        item.checked ? setRelationshipType(item.title) : setRelationshipType()
        break
      case 1:
        item.checked ? setCaseType(item.title) : setCaseType()
        break
      case 2:
        if (item.checked) {
          pos === '律师' ? setCourt(item.title) : setlawFirm(item.title)
        } else {
          pos === '律师' ? setCourt() : setlawFirm()
        }
        break
      default:
        break
    }
    const navmenu = JSON.parse(JSON.stringify(navMenu))
    navmenu[index].title = item.checked ? item.title : navmenu[index].default
    setnavMenu(navmenu)
  }, [navMenu, pos])

  useEffect(() => {
    if (subpos) {
      switch(subpos) {
        case '律师':
          setType('lawyer')
          break
        case '律所':
          setType('lawFirm')
          break
        case '法官':
          setType('judge')
          break
        case '法院':
          setType('court')
          break
        case '同事':
          setType('partner')
          break
        default:
          break
      }
    }
    // 格式化数据
    if (data && data.ret) {
      let {direction, relation, mergeData} = data.ret

      let matchData
      switch (relationshipType) {
        case '直接关系':
          matchData = direction
          break
        case '间接关系':
          matchData = relation
          break
        default:
          matchData = mergeData
          break
      }
      if (caseType) {
        matchData = matchData ? matchData.filter(item => item.analysis.some(i => i.reasonId === caseType)) : []
      }
      if (lawFirm) {
        matchData = matchData ? matchData.filter(item => item.lawFirm === lawFirm) : []
      }
      if (court) {
        matchData = matchData ? matchData.filter(item => item.court === court) : []
      }
      setRenderList(_.sortBy(matchData, 'total').reverse())

    }
  }, [data, relationshipType, caseType, lawFirm, subpos, court])

  return (
    <div className={style.panel}>
      <div className={style.header + ' clearfix'}>
        <h3 className={style.title}>{title}</h3>
        <PaneHeader name={name} pos={pos} navMenu={navMenu} selectItem={selectItem} caseTypes={caseTypes} locations={locations} subpos={subpos}/>
      </div>
      <BaseInfo location={location} subpos={subpos} type={type} renderList={renderList} name={name} pos={pos}  />
    </div>
  )
}

export default DetailPanel