import React, { useCallback } from "react"
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { List } from 'antd'
import style from './search.module.scss'
const { Item } = List

/* 
  结果列表
 */
const Lists = (props) => {
  const { lists, isHistory } = props

  // 点击 Item
  //    保存历史，保存当前信息
  const selectItem = useCallback((e) => {
    const {judge, court, lawyer, lawFirm} = e
    if (judge) {
      saveHistory({judge, title: court})
    } else if (lawyer) {
      saveHistory({lawyer, title: lawFirm})
    } else if (court) {
      saveHistory({court})
    } else if (lawFirm) {
      saveHistory({lawFirm})
    }
  }, [])

  // 保存历史记录（不超过50条）
  const saveHistory = (item) => {
    let history = window.localStorage.getItem('s_history')
    if (!history) {
      history = []
    } else {
      history = JSON.parse(history)
    }
    history = history.filter(h => !_.isEqual(h, item))
    history.unshift(item)
    if (history.length > 50) history.splice(50)
    window.localStorage.setItem('s_history', JSON.stringify(history))
  }

  // 渲染 结果项
  const renderItem = (item) => {
    if (item.judge)
      return renderJudge(item.judge, item.title)
    else if (item.lawyer)
      return renderLawyer(item.lawyer, item.title)
    else if (item.court) 
      return renderCourt(item.court)
    else if (item.lawFirm)
      return renderLawFirm(item.lawFirm)
  }
  const renderJudge= useCallback((judge, court) => {
    return (
      <div className={style.listItem + ' ' +style.judgeItem}>
        <Link to={`/judgesRelationships/detail/judge?name=${judge}&pos=法官&location=${court}`} onClick={() => selectItem({judge, court})} target='_blank'>
          <p>法官<span>{judge}</span></p><span>{court}</span>
        </Link>
      </div>
    )
  }, [selectItem])
  const renderLawyer= useCallback((lawyer, lawFirm) => {
    return (
      <div className={style.listItem + ' ' +style.lawyerItem}>
        <Link to={`/judgesRelationships/detail/lawyer?name=${lawyer}&pos=律师&location=${lawFirm}`} onClick={() => selectItem({lawyer, lawFirm})} target='_blank'>
          <p>律师<span>{lawyer}</span></p><span>{lawFirm}</span>
        </Link>
      </div>
    )
  }, [selectItem])
  const renderCourt= useCallback((court) => {
    return (
      <div className={style.listItem + ' ' +style.courtItem} >
        <Link to={`/judgesRelationships/detail/court?name=${court}&pos=法院`} onClick={() => selectItem({court})} target='_blank'>
          <p>法院<span>{court}</span></p>
        </Link>
      </div>
    )
  }, [selectItem])
  const renderLawFirm= useCallback((lawFirm) => {
    return (
      <div className={style.listItem + ' ' +style.lawFirmItem}>
        <Link to={`/judgesRelationships/detail/lawFirm?name=${lawFirm}&pos=律所`} onClick={() => selectItem({lawFirm})} target='_blank'>
          <p>律所<span>{lawFirm}</span></p>
        </Link>
      </div>
    )
  }, [selectItem])

  return (
      <List
        size='small'
        split={false}
        className={isHistory ? style.list + ' ' + style.historyList : style.list + ' ' + style.resultList}
        pagination={isHistory ? '' : {}}
        dataSource={lists}
        renderItem={(item, i) => (
          <Item
            key={i}
          >
            { renderItem(item)}</Item>
        )}
      >
      </List>
  )
}

export default Lists