import React, { useCallback, useEffect, useState } from "react"
import { connect } from 'react-redux'
import { message } from 'antd'
import { useLocation } from 'react-router-dom'
import tryRequest from '../../../../../utils/request'
import style from './judge.module.scss'
import Card from '../Card'
import _ from 'lodash'
import DetailWrapper from './JudgeDetail'
import { changePersonCount, changeLocaitonCount, changeCaseCount } from '../../../../../redux/actions/judgeAction'

/**
 * 法官图谱
 */
const JudgeDetail = (props) => {
  const { changePersonCount, changeLocaitonCount, changeCaseCount } = props
  const query = new URLSearchParams(useLocation().search)
  const name = query.get('name') || ''
  const court_id = query.get('court_id') || ''
  const pos = query.get('pos') || ''
  const title = query.get('location') || ''
  const [data, setData] = useState()  // 合作律师
  const [lawFirmData, setLawFirmData] = useState()  // 合作律所
  const [partnerData, setPartnerData] = useState([])  // 合作同事
  const [caseTypes, setCaseTypes] = useState([])  // 所有案件类型（合作律师/律所）
  const [lawFirms, setLawFirms] = useState([])  // 所有律所
  const [pCaseTypes, setPCaseTypes] = useState([])  // 所有案件类型（合作同事）
  const [location, setLocation] = useState('')  // !title && court_id时，作为法院名

  // 返回selectBar下拉菜单选项组
  const formatSelections = (arr) => {
    const arr_ = []
      arr.map((l, i) => arr_.push({id: i, title: l, checked: false}))
      return arr_
  }

  // 根据court_id获取对应法院
  const getLocation = useCallback(async () => {
    const res = await tryRequest({
      url: '/law/getCourt',
      method: 'POST',
      data: { court_id: Number(court_id) }
    })
    setLocation(_.get(res, 'data.ret[0].court'))
  }, [court_id])

  const getData = useCallback(async () => {
    const hide = message.loading('正在获取数据...', 0)
    // 获取合作律师
    const res = await tryRequest({
        url: '/law/judge',
        method: 'POST',
        data: { name, title: title || location }
    })
    hide()
    setData(res.data)
    const ret = _.get(res, 'data.ret')
    // 合作律师、律所、案件总数统计
    const { case_types, lawFirms, totalLawyer, totalCase, totalLawFirm } = ret
    changePersonCount(totalLawyer || 0)
    changeLocaitonCount(totalLawFirm || 0)
    changeCaseCount(totalCase || 0)
    if (_.isArray(case_types)) {
      setCaseTypes(formatSelections(case_types))
    }
    if (_.isArray(lawFirms)) {
      setLawFirms(formatSelections(lawFirms))
    }

    // 获取合作律所
    const lawFirm_res = await tryRequest({
      url: '/law/judge_LawFirm',
      method: 'POST',
      data: { name, title: title || location }
    })
    setLawFirmData(lawFirm_res.data)

    // 获取合作同事
    const partner_res = await tryRequest({
      url: '/law/judgePartner',
      method: 'POST',
      data: { name, title: title || location }
    })

    setPartnerData(partner_res.data)
    const pcases = _.get(partner_res, 'data.ret.case_types')
    if (_.isArray(pcases)) {
      setPCaseTypes(formatSelections(pcases))
    }

  }, [name, title, changeCaseCount, changeLocaitonCount, changePersonCount, location])

  useEffect(() => {
    if (pos === '法官' && !title && court_id) {
      getLocation()
    }
    if (name && (title || location)) {
      getData()
    }
  }, [getData, name, title, location, court_id, pos, getLocation])
  
  return (
    <div className={style.detailWrapper}>
      <Card name={name} location={title || location} pos='法官' />
      <DetailWrapper location={location} name={name} pos='法官' data={data} caseTypes={caseTypes} pCaseTypes={pCaseTypes} lawFirms={lawFirms} partnerData={partnerData} lawFirmData={lawFirmData} />
    </div>
  )
}

export default connect(
  state => ({}),
  {changePersonCount, changeLocaitonCount, changeCaseCount}
)(JudgeDetail)