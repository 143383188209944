import React, { useCallback, useEffect, useState } from 'react'
import { List, Row, Col, Button, Space } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'
import style from './baseInfo.module.scss'
import MyProcess from './MyProcess'
import CaseTypeChart from './CaseTypeChart'
import tryRequest from '../../../../utils/request'
import {changeCurrentTarget} from '../../../../utils/changeCurrentTarget'

/* 
  当前列表项的人、法院、律所
  点击跳转到对应页面
 */
const LinkItem = (props) => {
  const {subpos, name, pos, location, court_id} = props

  // 获取跳转链接
  const getRoute = useCallback(() => {
    let link = '/judgesRelationships/detail/'
    
    switch (subpos) {
      case '法官':
        link += 'judge'
        break
      case '律师':
        link += 'lawyer'
        break
      case '法院':
        link += 'court'
        break
      case '律所':
        link += 'lawFirm'
        break
      case '同事':
        link += pos === '法官' ? 'judge' : 'lawyer'
        break
      default:
        link += ''
        break
    }
    return link += `?name=${name}&pos=${subpos==='同事'?pos:subpos}${location?`&location=${location}`:`&court_id=${court_id}`}`
  }, [subpos, pos, location, name, court_id])
  
  return (
    <Col span={18} className={style.primary}>
      <Link to={getRoute()} target="_blank">{name}</Link>
    </Col>
  )
}

/* 
  法官同事 的 法院 需要单独获取
 */
const RenderCourt = (props) => {
  const {data, subpos, pos} = props
  const [courtName, setCourtName] = useState('')

  // 获取法院
  const getCourt = useCallback(async () => {
    const res = await tryRequest({
      url: '/law/getCourt',
      method: 'POST',
      data: {court_id: Number(data.court_id)}
    })
    const court = _.get(res, 'data.ret[0].court')
    data.court = court
    setCourtName(court)
  }, [data])

  useEffect(() => {
    if (data) {
      setCourtName(data.court)
    }
  }, [data])

  if (pos === '法官' && subpos === '同事' ) {
    return (
      <Row gutter={20}>
        <Col span={6} className={style.title}>任职法院</Col>
        <Col span={18} className={style.info}>
          {
            courtName ? courtName : <Button onClick={getCourt}>点击查看</Button>
          }
        </Col>
      </Row>
    )
  } else if (pos === '律师' && subpos === '法官') {
    return (
      <Row gutter={20}>
        <Col span={6} className={style.title}>任职法院</Col>
        <Col span={18} className={style.info}>{data.court}</Col>
      </Row>
    )
  } else {
    return ''
  }
}


/**
 * 列表信息
 *  左侧基本信息，右侧图标信息
 */
const BaseInfo = (props) => {
  const { location, renderList, personCount, caseCount, locationCount, subpos, type, pos } = props
  const query = new URLSearchParams(useLocation().search)
  const name = query.get('name') || 0
  const location_ = query.get('location') || 0
  const [page, setPage] = useState(0)

  // 跳转案件页，保存当前信息
  const goCase = (e) => {
    const {subname, subpos, sublocation} = e
    changeCurrentTarget(subname || '', subpos || '', sublocation || '', 1)
    changeCurrentTarget(name || '', pos || '', location || location_ || '')
  }

  return (
    <List className={style.list} split={false}
      pagination={{
        onChange: page => {
          setPage(page - 1)
        }
      }}
      dataSource={renderList || []}
      renderItem={(d, i) => {
        const color = `rgb(${parseInt(Math.random() * 255)}, ${parseInt(Math.random() * 255)}, ${parseInt(Math.random() * 255)})`
        return (
          <List.Item className={style.item} key={i}>
              <div className={style.content}>
                <div className={style.left}>
                  <Row gutter={20}>
                    <Col span={6} className={style.title + ' ' + style.order}>TOP{10 * page + i + 1}</Col>
                  </Row>
                  <Row gutter={20}>
                    <Col span={6} className={style.title}>{subpos}名</Col>
                    <LinkItem pos={pos} name={d[type]} subpos={subpos} location={d.court || d.lawFirm} court_id={d.court_id} />
                  </Row>
                  <Row gutter={20}>
                    <Col span={6} className={style.title}>关系类型</Col>
                    <Col span={18} className={style.info}>
                      <Space>
                        {
                          d.type.map((t, i) => (
                            <Button className={style.btn + ' ' + style.redBtn} key={i}>{t}</Button>
                          ))
                        }
                      </Space>
                    </Col>
                  </Row>
                  <RenderCourt data={d} pos={pos} subpos={subpos} />
                  <Row gutter={20}>
                    <Col span={6} className={style.title}>合作次数</Col>
                    <Col span={14} className={style.info}>
                      <MyProcess percent={100} color={color}/>
                    </Col>
                    <Col span={4} className={style.info}>{d.total}</Col>
                  </Row>
                  <Row gutter={20}>
                    <Col span={6} className={style.title}>直接合作</Col>
                    <Col span={14} className={style.info}>
                    <MyProcess percent={d.dTotalRatio} color={color}/>
                    </Col>
                    <Col span={4} className={style.info}>{d.dTotal}</Col>
                  </Row>
                  <Row gutter={20}>
                    <Col span={6} className={style.title}>间接合作</Col>
                    <Col span={14} className={style.info}>
                      <MyProcess percent={d.rTotalRatio} color={color}/>
                    </Col>
                    <Col span={4} className={style.info}>{d.rTotal}</Col>
                  </Row>
                  <Row gutter={20}>
                    {
                      subpos === '律师' 
                      ? <Col span={10} className={style.title}>合作案件及所在律所</Col>
                      : <Col span={6} className={style.title}>合作案件</Col>
                    }
                    
                    <Col span={subpos === '律师' ? 10 : 14 } className={style.info}>
                      <Link to={`/judgesRelationships/cases/list?personCount=${personCount}&caseCount=${caseCount}&locationCount=${locationCount}&pcourt_id=${d.court_id || ''}`} onClick={() => goCase({subname: d[type], subpos, sublocation: d.lawFirm || d.court})}>
                        <Button className={style.btn + ' ' + style.blueBtn} type='text'>点击查看</Button>
                      </Link>
                    </Col>
                  </Row>
                </div>
                <div className={style.right}>
                  <CaseTypeChart list={d.analysis} type={type} index={i}/>
                </div>
              </div>
            </List.Item>
        )
      }}
      >
    </List>
  )
}

export default connect(
  state => ({caseCount: state.judge.caseCount, personCount: state.judge.personCount, locationCount: state.judge.locationCount})
)(BaseInfo)