export const actionTypes = {
    LOGIN: 'LOGIN',
    CHANGETIME: 'CHANGETIME',
    CHANGEJCITY: 'CHANGEJCITY',
    CHANGEPERSONCOUNT: 'CHANGEPERSONCOUNT',
    CHANGECASECOUNT: 'CHANGECASECOUNT',
    CHANGELOCATIONCOUNT: 'CHANGELOCATIONCOUNT',
    CHANGEACITY: 'CHANGEACITY',
    LOGINFAILED: 'LOGINFAILED',
    SETLOGINSTATE: 'SETLOGINSTATE',
    REGISTER: 'REGISTER',
    REGISTERFAIL: 'REGISTERFAIL',
    CLEARERRMSG: 'CLEARERRMSG'
};