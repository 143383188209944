import React, { useState, useCallback } from "react"
import { useLocation } from "react-router-dom"

import RightMenu from "./RightMenu/RightMenu"
import Content from "./Content/Content"

import style from "./style.module.scss"
const Home = (props) => {
    const { isLogin } = props
    const [city_id, setCity_id] = useState();
    const [type_id, setType_id] = useState();
    const [timer, setTimer] = useState(['2020-01','2020-12']);
    const changeCity = useCallback(async (value) => { setCity_id(value) },[]);
    const changeType = useCallback(async (value) => { setType_id(value) },[]);
    const changeTimer = useCallback(async (value) => { setTimer(value) },[])
    const query = new URLSearchParams(useLocation().search)
    const dataType = parseInt(query.get('dataType')) || 0
    return (
        <div className={style.pageBox}>
            <div className={style.content} style={{display: 'flex'}}>
                <Content city_id={city_id} type_id={type_id} timer={timer} changeTimer={changeTimer}></Content>
                <RightMenu noSelect={dataType === 4 || !isLogin} changeCity={changeCity} changeType={changeType} changeTimer={changeTimer}></RightMenu>
            </div>
        </div>
    )
}
export default Home