import {
  actionTypes
} from "../actionTypes";

const initState = {
  city_id: null,
  city_name: '',
  caseCount: 0,
  personCount: 0,
  locationCount: 0
};

function reducer(state = initState, action) {
  switch (action.type) {
      case actionTypes.CHANGEJCITY:
          return {
              ...state,
              city_id: action.state,
          };
      case actionTypes.CHANGECASECOUNT:
          return {
            ...state,
            caseCount: action.state
          }
      case actionTypes.CHANGEPERSONCOUNT:
          return {
            ...state,
            personCount: action.state
          }
      case actionTypes.CHANGELOCATIONCOUNT:
          return {
            ...state,
            locationCount: action.state
          }
      default:
          return state;
  }
}

export default reducer;