import React, { useCallback, useEffect } from 'react'
import { Chart } from '@antv/g2';
import style from './result.module.scss'
const id = 'priceTrend'

/* 
  二手房走势图
 */
const PriceTrend = (props) => {
  const  {data} = props

  // 渲染图表
  const renderChart = useCallback(() => {
    const chart = new Chart({
      container: id,
      autoFit: true,
      height: 500,
    });
    
    chart.data(data);
    chart.scale({
      time: {
        range: [0, 1],
      },
      value: {
        min: 0,
        nice: true,
      },
    });
    
    chart.tooltip({
      showCrosshairs: true, // 展示 Tooltip 辅助线
      shared: true,
    });
    
    chart.line().position('time*value').label('value');
    chart.point().position('time*value');
    
    chart.render();
  }, [data])

  useEffect(() => {
    if (data) {
      data.map(d => d.time = `${d.year}年${d.month}月`)
      document.getElementById(id).innerHTML = null
      renderChart()
    }
  }, [renderChart, data])

  return (
    <div className={style.trendChart}>
      <p className={style.title}>本小区二手房价格走势</p>
      <div id={id}></div>
    </div>
  )
}

export default PriceTrend