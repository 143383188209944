import React from 'react'
import _ from 'lodash'
import YearRatioChart from './YearRatioChart'
import { Col, Row } from 'antd'
import style from './history.module.scss'

const YearRatio = (props) => {
  const { data } = props

  return (
    <div className={style.box}>
      <p className={style.title}>司法拍卖平台市场历年占有率数据</p>
      <div>
        <Row>
          {
            _.isArray(data) ? data.map((c, i) => (
              <Col span={8} key={c+i}>
                <YearRatioChart data={c} id={`yearRatio${i}`} />
              </Col>
            ))
            : ''
          }
        </Row>
      </div>
    </div>
  )
}

export default YearRatio