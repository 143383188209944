import React from 'react'
import UerRegister from '../components/register'

const Register = (props) => {

    return (
        <div>
            <UerRegister {...props} />
        </div>
    )
}

export default Register