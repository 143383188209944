import React from "react"
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import { routes } from "./router.config"
import Cookies from 'js-cookie'

const Routers = () => {
    return (
        <Router>
            <Switch>
                {routes.map((route, i) => (
                    <RouteWithSubRoutes key={i} {...route} />
                ))}
            </Switch>
        </Router>
    )
}

function RouteWithSubRoutes(route) {
    return (
        <Route
            path={route.path}
            render={props => {
                const { search = '', pathname = '/' } = props.location
                const token = Cookies.get('token')
                props.isLogin = Boolean(token)
                return (
                    // pass the sub-routes down to keep nesting
                    !route.token || token ? <route.component {...props} routes={route.children} />
                        : <Redirect to={{
                            pathname: "/login",
                            state: { from: pathname + search },
                          }}></Redirect>
                )
            }}
        />
    );
}
export default Routers