import React, { useState, useEffect, useCallback } from "react"
import { useLocation } from 'react-router-dom'
import { connect } from "react-redux"
import { message } from 'antd'
import _ from 'lodash'
import Card from '../Card'
import ListPanel from '../ListPanel'
import tryRequest from '../../../../../utils/request'
import style from './court.module.scss'
import { changeCaseCount } from '../../../../../redux/actions/judgeAction'

/**
 * 法院图谱
 */
const Court = (props) => {
  const { changeCaseCount } = props
  const query = new URLSearchParams(useLocation().search)
  const name = query.get('name') || ''
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  // 获取数据
  const getData = useCallback(async (page, pageSize) => {
    const hide = message.loading('正在获取数据...', 0)

    const res = await tryRequest({
      url: '/law/court',
      method: 'POST',
      data: { name, page, pageSize }
    })
    hide()
    const {data, totalCase, totalJudge} = res.data.ret
    totalJudge && setTotal(totalJudge)
    if (_.isArray(data) && data.length) {
      setData(data)
    }
    if (totalCase)
    changeCaseCount(totalCase || 0)
  }, [name, changeCaseCount])

  // 点击下一页
  const getCurrent = useCallback((page, pageSize = 10) => {
    if (page && pageSize) {
      getData(page, pageSize)
    }
  }, [getData])

  useEffect(() => {
    getData(1, 10)
  }, [getData])

  return (
    <div className={style.detailWrapper}>
      <Card name={name} pos='法院' />
      <div className={style.contentWrapper}>
        <ListPanel name={name} data={data} subpos="法官" getCurrent={getCurrent} total={total} type='judge' />
      </div>
    </div>
  )
}

export default connect(
  state => ({}),
  { changeCaseCount }
)(Court)