import React, { useCallback, useState, useEffect } from 'react'
import { Input, Button, Select, AutoComplete } from 'antd'
import style from './style.module.scss'
import comstyle from '../home/Content/header.module.scss'
import { city } from '../../static/mock/cityList.json'
const { Option } = Select

/* 
  搜索框
 */
const SearchInput = (props) => {
  const [input, setInput] = useState('')
  const [options, setOptions] = useState([]);

  const { changeCity, selectHouse, matchSearch, matchList } = props

  // 选择城市
  const selectCity = useCallback((city_id) => {
    changeCity(city_id)
  }, [changeCity])

  // 改变输入
  const changeInput = useCallback((text) =>{
    setInput(text)
  }, [])

  useEffect(() => {
    matchList && matchList.length && setOptions(matchList);
  }, [matchList])

  return (
    <div className={style.searchWrapper}>
      <div className={style.cityWrapper}>
        <img src="assets/images/location.png" alt="location" className={style.cityIcon} />
        <Select
          showSearch
          allowClear
          bordered={false}
          showArrow={false}
          defaultValue="城市"
          onChange={selectCity}
          className={style.citySelect}
          filterOption={(input, option) => option.selectOption.toLowerCase().indexOf(input.toLowerCase()) >= 0
          } >
          {
            city.map(c => {
              return <Option value={c.city_id} key={c.city_id} selectOption={c.cityName+c.citySpell} className={style.cityOption}>{c.cityName}</Option>
            })
          }
        </Select>
      </div>
      <AutoComplete className={style.autoComplete}
        onChange={changeInput}
        onSearch={matchSearch}
        options={options}
        onSelect={selectHouse}>
        <Input
          placeholder="请输资产地址"
          className={style.searchInput}
          suffix={
            <Button className={[comstyle.primaryBtn, comstyle.selected, style.font20].join(' ')} onClick={() => selectHouse(input)}>确认</Button>
          } />
      </AutoComplete>
    </div>
  )
}

export default SearchInput