import React from 'react'
import DetailPanel from '../DetailPanel'
import { Tabs } from 'antd'
import style from './lawyer.module.scss'
const { TabPane } = Tabs;

/* 
  律师详情
 */
const LawyerDetail = (props) => {
  const {data, name, pos, caseTypes, courts, courtData, pCaseTypes, partnerData} = props
  return (
    <div className={style.contentWrapper}>
      <Tabs className={style.content.concat(' ', pos==='律师' ? style.lawyerContent : '')} defaultActiveKey="1" type="card" tabBarGutter={64} tabBarStyle={{fontSize: '28px', padding: '68px 0 52px'}}>
        <TabPane className={style.pane} tab="合作法官" key="1">
          <DetailPanel title='合作法官汇总' subpos='法官' data={data} name={name} pos={pos} caseTypes={caseTypes} locations={courts}/>
        </TabPane>
        <TabPane className={style.pane} tab="合作法院" key="2">
          <DetailPanel title='合作法院汇总' subpos='法院' data={courtData} name={name} pos={pos} caseTypes={caseTypes} locations={courts}/>
        </TabPane>
        <TabPane className={style.pane} tab="合作同事" key="3">
          <DetailPanel title='合作同事汇总' subpos='同事' data={partnerData} name={name} pos={pos} caseTypes={pCaseTypes} locations={courts}/>
        </TabPane>
      </Tabs>
    </div>
  )
}

export default LawyerDetail