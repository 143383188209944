import React, { useState, useCallback } from "react"
import { Button, Menu, Dropdown, Input, DatePicker } from "antd"
import { FilterOutlined, EnvironmentOutlined, AppstoreAddOutlined } from "@ant-design/icons"
import Cookies from 'js-cookie'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { city } from "../../../static/mock/cityList.json"
import style from "./style.module.scss"
const { RangePicker } = DatePicker;

const FilterMenu = (props) => {
    const history = useHistory();
    const [showCity, setShowCity] = useState(false)
    const [cityName, setCityName] = useState('全国')
    const [type, setType] = useState('全部类型')
    const [cities, setCities] = useState(city)
    const [month, setMonth] = useState([])
    const { changeCity, changeTimer, changeType, timeList } = props
    //切换城市
    const clickCity = useCallback((e, value, id = null) => {
        sessionStorage.setItem('cityName', value)
        setCityName(value)
        setShowCity(false)
        changeCity(id)
    }, [changeCity])
    // 选择时间
    const changeTime = useCallback((value, d) => {
        timeList.map(t => t.map(t => {
            t[0] = d[0]
            t[1] = d[1]
            return t
        }))
        changeTimer(d)
    }, [changeTimer, timeList])
    // 选择类型
    const selectType = useCallback((value, name) => {
        changeType(value)
        setType(name)
        sessionStorage.setItem('type',name)
    }, [changeType])
    //输入城市
    const inputCity  = useCallback(e=>{
        const value = e.target.value.toLocaleUpperCase()
        const newCities = city.filter(item=>{
            return item.cityName.includes(value)||item.citySpell.includes(value)
        })
        setCities(newCities)
    },[])
    const handleOpen = (open) => {
        if (open && !Cookies.get('token')) {
            history.push({
                pathname: '/login',
                state: { from: history.location.pathname }
            })
        }
      }
    const cityMenu = (
        <div className={style.menuBox}>
            <Menu>

                <Menu.Item key="0" disabled={true}>
                    <Input onChange={inputCity} placeholder="输入城市搜索" />
                </Menu.Item>
                <Menu.Item key="1" onClick={e => { clickCity(e, '全国') }}>全国</Menu.Item>
                {
                    cities.map(item => {
                        return <Menu.Item onClick={e => { clickCity(e, item.cityName, item.city_id) }} key={item.city_id}>{item.cityName}</Menu.Item>
                    })
                }
            </Menu>
        </div>
    )
    const typeMenu = (
        <Menu>
            <Menu.Item key="0" onClick={() => selectType(null, '全部类型')}>全部类型</Menu.Item>
            <Menu.Item key="1" onClick={() => selectType(1, '住宅')}>住宅</Menu.Item>
            <Menu.Item key="2" onClick={() => selectType(2, '商业')}>商业</Menu.Item>
            <Menu.Item key="3" onClick={() => selectType(3, '工业')} >工业</Menu.Item>
        </Menu>
    )

    const disabledDate = current => {
        if (!month || month.length === 0) {
            return false;
        }
        const tooLate = month[0] && current.diff(month[0], 'months') > 11;
        const tooEarly = month[1] && month[1].diff(current, 'months') > 11;
        return tooEarly || tooLate;
    };

    return (
        <>
            <div className={style.filter}>
                <div className={style.title}><FilterOutlined className={style.btnIcon} />条件筛选</div>
                <Dropdown visible={showCity} overlay={cityMenu} trigger={['click']} >
                    <Button onClick={e => { setShowCity(!showCity) }} block icon={<EnvironmentOutlined className={style.btnIcon} />}>{cityName}</Button>
                </Dropdown>
                <RangePicker
                    onOpenChange={handleOpen}
                    disabledDate={disabledDate}
                    className={style.timeBox}
                    picker="month"
                    onChange={changeTime}
                    onCalendarChange={value => {
                        setMonth(value);
                    }} />

                <Dropdown overlay={typeMenu} trigger={['click']} >
                    <Button block icon={<AppstoreAddOutlined className={style.btnIcon} />}>{type}</Button>
                </Dropdown>
            </div>
        </>
    )
}



export default connect(
    state => ({timeList: state.chart.timeList})
)(FilterMenu)