import React from 'react'
import { Table } from 'antd'
import style from './history.module.scss'
const columns = [
  {
    title: '年份',
    dataIndex: 'period',
    key: 'period',
  },
  {
    title: '挂拍量（套）',
    dataIndex: 'show_cnt',
    key: 'show_cnt',
  },
   {
    title: '成交量（套）',
    dataIndex: 'deal_cnt',
    key: 'deal_cnt',
  },
  {
    title: '挂拍金额（亿元）',
    dataIndex: 'show_amount',
    key: 'show_amount',
  },
  {
    title: '成交金额（亿元）',
    key: 'deal_amount',
    dataIndex: 'deal_amount'
  },
  {
    title: '报名参与人数',
    dataIndex: 'apply_sum',
    key: 'apply_sum'
  },
];

const YearData = (props) => {
  const { data } = props

  return (
    <div className={style.box}>
      <p className={style.title}>司法拍卖历年数据</p>
      <div>
        {
          data ? <Table className={style.table} dataSource={data} columns={columns} rowKey='period' /> : ''
        }
      </div>
    </div>
  )
}

export default YearData