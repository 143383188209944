import React, { useState, useEffect, useCallback } from "react"
import { Chart } from '@antv/g2';
import style from './style.module.scss'
import DateRangePicker from './DateRangePicker'
const id = 'courtOrder'

const DataView = (props) => {
  const {data, title, timer, dataType, getSingleData, titleName} = props
  const [chartData, setChartData] = useState(data)

  // 设置chart数据
  const getChartData = useCallback((params) => {
    if (params) { // 子元素改变时间
        getSingleData(params)
    }
    
    setChartData(data)
  }, [data, getSingleData])

  const changeTime = useCallback((date) => {
      document.getElementById(id).innerHTML = null
      getChartData({ part: 9, start: date[0], end: date[1], dataType })
  }, [getChartData, dataType])

  const renderChart = useCallback(() => {
    const chart = new Chart({
      container: id,
      autoFit: true,
      height: 500,
    });
    
    chart.data(chartData);
    chart.scale(`${title}_cnt`, { nice: true });
    chart.coordinate().transpose();
    chart.tooltip({
      showMarkers: false
    });
    chart.interaction('active-region');
    chart.interval().position(`court*${title}_cnt`);
    chart.render();
  }, [chartData, title])

  useEffect(() => {
    document.getElementById(id).innerHTML = null
    if (data) {
      getChartData()
      renderChart()
    }
  }, [renderChart, timer, data, chartData, getChartData])
  return (
    <div className={style.box}>
      <div className={style.header}>
          <div className={style.left}>
              <div className={style.title}>{timer[0] + '-' + timer[1]}
              <span className={style.special}> {sessionStorage.getItem('cityName') ||'全国'} {sessionStorage.getItem('type')} </span>推动上拍{titleName}法院 TOP 10 排行榜
              </div>
          </div>
          <div className={style.right}>
              <DateRangePicker timer={timer} changeTime={(date) => changeTime(date)} />
          </div>
      </div>
      <div className={style.loop} id={id}></div>
  </div>
  )
}

export default DataView