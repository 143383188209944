import React, { useEffect, useCallback } from 'react'
import { Chart } from '@antv/g2';
import _ from 'lodash'
import style from './detail.module.scss'


/* 
  案件类型图表
 */
const CaseTypeChart = (props) => {
  const { list, type, index } = props
  const id = 'chartType' + type + index

  // 渲染表
  const renderChart = useCallback(() => {
    const chart = new Chart({
      container: id,
      autoFit: true,
      height: 400,
    });
    chart.data(list);
    chart.coordinate('theta', {
      radius: 0.75,
      innerRadius: 0.6,
    });
    chart.legend({
      position: 'top-right', 
      layout: 'vertical'
    })
    chart
      .interval()
      .adjust('stack')
      .position('percent')
      .color('reasonId')
      .tooltip('reasonId*percent', (reasonId, percent) => {
        percent = parseInt(percent * 100) + '%';
        return {
          name: reasonId,
          value: percent,
        };
      });
    
    chart.interaction('element-active');
    
    chart.render();
  }, [list, id])

  useEffect(() => {
    if (list) {
      // 计算每一项数据百分比
      if (list.length === 1) {
        list[0].percent = 1
      } else {
        let total = 0
        list.map(l => total += l.cnt)
        _.map(list, l => l.percent = Number((l.cnt / total).toFixed(2)))
      }
      document.getElementById(id).innerHTML = null
      renderChart()
    }
  }, [list, renderChart, id])

  return (
    <div className={style.chart}>
      <p className={style.title}>合作案件类型分布</p>
      <div id={id}></div>
    </div>
  )
}

export default CaseTypeChart