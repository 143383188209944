import Home from "../pages/home"
import JudgesRelationships from '../pages/judgesRelationships'
import JudgeSearch from '../components/judgesRelationships/Content/Search/index'
import JudgeDetail from '../components/judgesRelationships/Content/Detail/Judge/index'
import LawyerDetail from '../components/judgesRelationships/Content/Detail/Lawyer/index'
import CourtDetail from '../components/judgesRelationships/Content/Detail/Court/index'
import LawFirmDetail from '../components/judgesRelationships/Content/Detail/LawFirm/index'
import Cases from '../components/judgesRelationships/Content/Detail/Case/index'
import CaseList from '../components/judgesRelationships/Content/Detail/Case/CaseList'
import CaseDetail from '../components/judgesRelationships/Content/Detail/Case/CaseDetail'
import AssetsEvaluation from '../pages/assetsEvaluation'
import Login from '../pages/login';
import Register from '../pages/register';
import Demo from "../pages/demo"
import Test from '../pages/test'

export const routes = [
    {
        path: '/',
        component: Home,
        exact: true,
        token: false,
        comment: "主页",
    },
    {
        path: '/judgesRelationships',
        component: JudgesRelationships,
        token: true,
        comment: "司法关系图谱",
        children: [
            {
                path: '/judgesRelationships/search',
                component: JudgeSearch,
                exact: true,
                token: true,
                comment: "搜索",
            },
            {
                path: '/judgesRelationships/detail/judge',
                component: JudgeDetail,
                token: true,
                comment: "法官详情",
            },
            {
                path: '/judgesRelationships/detail/lawyer',
                component: LawyerDetail,
                token: true,
                comment: "律师详情",
            },
            {
                path: '/judgesRelationships/detail/court',
                component: CourtDetail,
                token: true,
                comment: "法官详情",
            },
            {
                path: '/judgesRelationships/detail/lawFirm',
                component: LawFirmDetail,
                token: true,
                comment: "律师详情",
            },
            {
                path: '/judgesRelationships/cases',
                component: Cases,
                token: true,
                comment: "案件",
                children: [
                    {
                        path: '/judgesRelationships/cases/list',
                        component: CaseList,
                        token: true,
                        comment: '案件列表'
                    },
                    {
                        path: '/judgesRelationships/cases/detail',
                        component: CaseDetail,
                        token: true,
                        comment: '案件详情'
                    },
                ]
            },
        ]
    },
    {
        path: '/assetsEvaluation',
        component: AssetsEvaluation,
        token: true,
        comment: '资产评估'
    },
    {
        path: '/login',
        component: Login,
        token: false,
        comment: '登录'
    },
    {
        path: '/register',
        component: Register,
        token: false,
        comment: '注册'
    },
    {
        path: '/demo',
        component: Demo,
        exact: true,
        token: true,
        comment: "演示",
    },
    {
        path: '/test',
        component: Test,
        exact: true,
        token: false,
        comment: "测试",
    }
]