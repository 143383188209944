import React from 'react'
import style from './panel.module.scss'
import SelectBar from './SelectBar'

/* 
  面板头部
    标题
    selectBar + 说明
 */
const PaneHeader = (props) => {
  const { navMenu, selectItem, caseTypes, name, pos, locations, subpos } = props
  return (
    <div className={style.paneHeader}>
      <SelectBar navMenu={navMenu} selectItem={selectItem} caseTypes={caseTypes} locations={locations} />
      <div className={style.rightInfo}>
        <span>说明：</span>
        <div className={style.info}>
          <p>{`“直接关系”指和${name}${pos}直接合作过的${subpos}们`}</p>
          <p>{`“间接关系”指${name}${pos}通过判决案件，找到的关联${subpos}`}</p>
        </div>
      </div>
    </div>
  )
}

export default PaneHeader