import React, { useCallback, useEffect, useState } from "react"
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { message } from 'antd'
import tryRequest from '../../../../../utils/request'
import style from './lawyer.module.scss'
import Card from '../Card'
import _ from 'lodash'
import DetailWrapper from './LawyerDetail'
import { changePersonCount, changeLocaitonCount, changeCaseCount } from '../../../../../redux/actions/judgeAction'

/**
 * 律师图谱
 */
const Lawyer = (props) => {
  const { changePersonCount, changeLocaitonCount, changeCaseCount } = props
  const query = new URLSearchParams(useLocation().search)
  const name = query.get('name') || ''
  const title = query.get('location') || ''
  const [data, setData] = useState()  // 合作法官
  const [courtData, setCourtData] = useState()  // 合作法院
  const [partnerData, setPartnerData] = useState([])  // 合作同事
  const [caseTypes, setCaseTypes] = useState([])  // 所有案件类型（合作法官/法院）
  const [courts, setCourts] = useState([])  // 所有律所
  const [pCaseTypes, setPCaseTypes] = useState([])  // 所有案件类型（合作同事）

  // 返回selectBar下拉菜单选项组
  const formatSelections = (arr) => {
    const arr_ = []
      arr.map((l, i) => arr_.push({id: i, title: l, checked: false}))
      return arr_
  } 

  const getData = useCallback(async () => {
    const hide = message.loading('正在获取数据...', 0)
    // 获取合作法官
    const res = await tryRequest({
        url: '/law/lawyer_judge',
        method: 'POST',
        data: { name, title }
    })
    setData(res.data)
    hide()
    const ret = _.get(res, 'data.ret')
    // 合作法官、法院、案件总数统计
    const { case_types, courts, totalJudge, totalCase, totalCourt } = ret
    changePersonCount(totalJudge || 0)
    changeLocaitonCount(totalCourt || 0)
    changeCaseCount(totalCase || 0)
    if (_.isArray(case_types)) {
      setCaseTypes(formatSelections(case_types))
    }
    if (_.isArray(courts)) {
      setCourts(formatSelections(courts))
    }

    // 获取合作法院
    const court_res = await tryRequest({
      url: '/law/lawyer_court',
      method: 'POST',
      data: { name, title }
    })
    setCourtData(court_res.data)

    // 获取合作同事
    const partner_res = await tryRequest({
      url: '/law/lawyerPartner',
      method: 'POST',
      data: { name, title }
    })

    setPartnerData(partner_res.data)
    const pcases = _.get(partner_res, 'data.ret.case_types')
    if (_.isArray(pcases)) {
      setPCaseTypes(formatSelections(pcases))
    }
    
  }, [name, title, changeCaseCount, changeLocaitonCount, changePersonCount])

  useEffect(() => {
    if (name && title) {
      getData()
    }
  }, [getData, name, title])
  
  return (
    <div className={style.detailWrapper}>
      <Card name={name} title={title} pos='律师' />
      <DetailWrapper name={name} pos='律师' data={data} caseTypes={caseTypes} pCaseTypes={pCaseTypes} courts={courts} partnerData={partnerData} courtData={courtData} />
    </div>
  )
}

export default connect(
  state => ({}),
  {changePersonCount, changeLocaitonCount, changeCaseCount}
)(Lawyer)