import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import tryRequest from '../../utils/request'
import Header from '../home/Content/Header'
import comstyle from '../home/style.module.scss'
import RightMenu from '../home/RightMenu/RightMenu'
import AssetResults from './Results'
import style from './style.module.scss'
import { changeCity } from '../../redux/actions/assetsAction'
import SearchInput from './SearchInput'
import { message } from 'antd'

/**
 * 资产评估
 */
const AssetsEvaluation = (porps) => {
  const { changeCity, city_id } = porps
  const [data, setData] = useState()
  const [matchList, setMatchList] = useState([])

  // 获取 预测结果
  const getData = useCallback(async(params) => {
    const hide = message.loading('正在获取数据...', 0)
    const res = await tryRequest({
      url: '/house/predict',
      method: "POST",
      data: params
    })
    hide()
    setData(res.data)
  }, [])

  // 获取 搜索结果
  const searchData = useCallback(async(params) => {
    const { search, city_id } = params
    const res = await tryRequest({
      url: `/house/houseTips?search=${search}&city_id=${city_id}`,
      method: 'GET'
    })
    setMatchList(res.data || [])
  }, [])

  const matchSearch = useCallback((search) => {
    //如果包含英文则等待输入中文后请求
    if(search && search.trim().length >= 3){
      !/[a-zA-Z]/i.test(search) && searchData({search, city_id})
    }
  }, [searchData, city_id])

  const selectHouse = useCallback((address) => {
    getData({city_id, address})
  }, [getData, city_id])
  
  return (
    <div className={comstyle.pageBox}>
      <div className={[comstyle.content, style.assetContent].join(' ')}>
        <div className={style.headerWrapper}>
          <div className={style.nav}>
            <Header selectIndex={2} />
          </div>
          <div className={style.rightInfo}>
            <RightMenu noSelect={true} highlight={false} />
          </div>
        </div>
        <div className={style.panel}>
          <h3 className={style.title}>资产评估</h3>
          <SearchInput changeCity={changeCity} matchSearch={matchSearch} matchList={matchList} selectHouse={selectHouse} />
          {
            data && <AssetResults data={data} />
          }
        </div>
      </div>
    </div>
  )
}

export default connect(
  state => ({ city_id: state.assets.city_id, cityName: state.assets.cityName}),
  { changeCity }
)(AssetsEvaluation)