import React, { useState } from 'react'
import _ from 'lodash'
import {Form, Input, Button, Checkbox, Popover} from 'antd'
import {connect} from 'react-redux'
import {setInfo, clearErrMsg} from '../../redux/actions/userAction'
import style from './style.module.scss'
import {Redirect, Link} from 'react-router-dom'
import {MoneyCollectFilled, BuildFilled, CodepenSquareFilled, BankFilled, CustomerServiceTwoTone, SwapOutlined} from '@ant-design/icons';
import PayModal from './payModal'
import ConcatPop from './concatPop'

const layout = {
    labelCol: {
        span: 2,
    },
    wrapperCol: {
        span: 24,
    },
};
const tailLayout = {
    wrapperCol: {
        span: 24,
    },
};

const concatContent = (
    <div>
        <img className={style.concatImg} src="http://static.yfbudong.com/horuscontact.png" alt="客服联系方式"/>
    </div>
);

const Login = (props) => {
    const {setInfo, errMsg, location, isLogin, showLogin, errCode, clearErrMsg} = props
    const [status, setStatus] = useState(0);
    const [remember, setRemember] = React.useState(true);

    const onFinish = (params) => {
        params.status = status
        setInfo(params)
    };

    if (isLogin) {
        let path = _.get(location, 'state.from')

        if (path === '/login') {
            return <Redirect to='/'/>
        } else {
            return <Redirect to={path}/>
        }
    }

    const hideModal = () => {
        clearErrMsg()
    }

    const LoginForm = ()=>(
        <Form {...layout} size="large" name="basic" onFinish={onFinish} className={style.loginForm}>
            <Form.Item labelAlign="left" name="username" rules={[{required: true, message: '请输入用户名！',},]}>
                <Input placeholder="请输入用户名"/>
            </Form.Item>
            <Form.Item labelAlign="left" name="password" rules={[{required: true, message: '请输入密码',},]}>
                <Input.Password placeholder="请输入密码"/>
            </Form.Item>
            <Form.Item {...tailLayout} name="remember" className={style.remBox}>
                <div>
                    <Checkbox style={{width:'40%'}} checked={remember} onClick={()=>setRemember(!remember)}>记住我</Checkbox>
                    <ConcatPop text={'无法验证？'} />
                    <ConcatPop text={'忘记密码？'} />
                </div>
            </Form.Item>
            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit" block>登录</Button>{errMsg ? <span>{errMsg}</span> : ''}
            </Form.Item>

            <div className={style.boxExtra}> 登录即代表默认同意 <span
                className={style.extraTip}>「用户协议」</span> 和 <span
                className={style.extraTip}>「隐私政策」</span></div>
        </Form>)

    const RoleList = ()=>(<ul className={style.boxTab}>
        <li className={style.tabItem} onClick={() => setStatus(1)}>
            <MoneyCollectFilled/>
            <div className={style.wrapperName}>
                <div className={style.role}>金融机构</div>
                <div className={style.tips}>银行、非银金融、AMC、民间资本、外资</div>
            </div>
        </li>
        <li className={style.tabItem} onClick={() => setStatus(2)}>
            <BuildFilled />
            <div className={style.wrapperName}>
                <div className={style.role}>处置服务商</div>
                <div className={style.tips}>律所、处置公司、拍辅机构、其他渠道</div>
            </div>
        </li>
        <li className={style.tabItem} onClick={() => setStatus(3)}>
            <CodepenSquareFilled />
            <div className={style.wrapperName}>
                <div className={style.role}>科研机构</div>
                <div className={style.tips}>大学、商业研究组织等</div>
            </div>
        </li>
        <li className={style.tabItem} onClick={() => setStatus(4)}>
            <BankFilled />
            <div className={style.wrapperName}>
                <div className={style.role}>政府机关</div>
                <div className={style.tips}>法院、房管部门等</div>
            </div>
        </li>
    </ul>)

    return (
        <div className={style.app}>
            <video autoPlay loop muted>
                <source src="http://static.yfbudong.com/horus.mp4" type="video/mp4" />
            </video>

            <div className={style.loginWrapper}>
                <div className={style.loginHeader}><div className={style.title}>荷鲁斯大数据平台</div></div>
                <div className={style.loginContent}>
                    <div className={style.contentTitle}>全视之眼、逆熵而行</div>
                    <div className={style.contentLine}></div>
                    <div className={style.contentSubtitle}>不良资产大数据服务&合作平台</div>
                </div>
                <div className={style.loginBox}>
                    <section>
                        <div className={style.boxTitle}> {status ?  '客户登陆' : '选择登陆角色'}
                            {status ?  <span className={style.exchangeBtn} onClick={() => setStatus(0)}><SwapOutlined />切换角色</span>
                                :<Link to='/register' target="_blank"><button type="button" className={style.registerBtn}><span>注册&试用</span></button></Link>
                            }
                        </div>
                    </section>
                    {status ? <LoginForm /> : <RoleList />}

                    <section className={style.guidBox}>
                        <Popover className={style.mt10} content={concatContent} title="商务部负责人">
                            <CustomerServiceTwoTone twoToneColor="#00c033"/>
                            <p>合作</p>
                        </Popover>
                    </section>
                </div>
                <div className={style.contact}>联系人：屈经理 15962417535</div>
            </div>

            {showLogin ? <LoginForm /> : null}

            <PayModal errCode={errCode} errMsg={errMsg} hideModal={hideModal} />
        </div>
    )
}

export default connect(
    state => ({errMsg: state.user.errMsg, errCode: state.user.errCode, isLogin: state.user.isLogin, showLogin: state.user.showLogin}),
    {setInfo, clearErrMsg}
)(Login)