import React from 'react'
import Lists from './Lists'
import style from './search.module.scss'

/* 
  搜索历史
 */
const HistoryList = (props) => {
  const { history, goSearch } = props

  return (
    <div className={style.historyWrapper}>
      <div className={style.history}>
        { history && history.length ? <Lists lists={history} goSearch={goSearch} isHistory={true} /> : '' }
        <p className={style.footer}>搜索历史</p>
      </div>
    </div>
  )
}

export default HistoryList