import React, { useState, useEffect } from "react"
import {Button} from "antd"
import { Link } from 'react-router-dom'
import style from "./header.module.scss"

const Header = (props) => {
    const [initNavList] = useState([
        {
            path: '/',
            text: '行业数据'
        },
        {
            path: '/judgesRelationships',
            text: '司法关系图谱'
        },
        {
            path: '/assetsEvaluation',
            text: '资产评估'
        }
    ])
    const { selectIndex = 0 } = props
    const [navList, setNavList] = useState([])

    useEffect(() => {
        if (selectIndex === 2) {
            setNavList(initNavList.reverse())
        } else if (selectIndex === 0) {
            setNavList(initNavList)
        }
    }, [selectIndex, initNavList])

    return (
        <div className={style.header}>
            <div className={style.rotundity}></div>
            {
                navList.map((item, i) => (
                    <Link key={item.path} to={item.path}>
                        <Button className={i === 0 ? [style.primaryBtn, style.selected].join(' ') : style.primaryBtn} type="text">{item.text}</Button>
                    </Link>
                ))
            }
        </div>
    )
}

export default Header