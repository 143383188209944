import React, { useEffect, useCallback } from "react"
import { Chart } from '@antv/g2';
import style from "./style.module.scss"
import formatPrice from '../../utils/formatNum'
import DateRangePicker from './DateRangePicker'

const DataView = (props) => {
    const [total, setTotal] = React.useState(0)
    const {data,id,title,titleName, timer, getSingleData, dataType } = props
    const [chartData, setChartData] = React.useState(data)

    // 设置chart数据
    const getChartData = useCallback((params) => {
        if (params) { // 子元素改变时间
            getSingleData(params)
        }
        setChartData(data)
    }, [data, getSingleData])

    const changeTime = useCallback((date) => {
        document.getElementById(id).innerHTML = null
        getChartData({ part: 1, start: date[0], end: date[1], dataType })
    }, [getChartData, dataType, id])

    // 渲染chart
    const renderChart = useCallback(() => {
        const chart = new Chart({
            container: id,
            autoFit: true,
            height: 500,
        });
        chart.data(chartData);
        chart.scale({
            month: {
                range: [0, 1]
            },
            value: {
                alias: `累计${titleName}金额`,
                nice: true,
            },

        });
        chart.axis('value');
        chart.legend({
            position: 'bottom',
        });
        chart.tooltip({
            showCrosshairs: true,
            showTitle: false,
            itemTpl:
            '<li style="list-style: none;padding-bottom:10px">' +
            '<span style="background-color:{color};" class="g2-tooltip-marker"></span>' +
            '{name}<br/>' +
            `<span style="padding-left: 16px">总${titleName}金额：{count}/万元</span><br/>` +
            '</li>',
        });
        chart
            .line()
            .position('month*value')
            .color('#4FAAEB')
            .label('value')
            .shape('timer', timer => {
                if (timer === '去年同期数据') {
                    return 'dash'
                }
            })
            .tooltip('month*value', (name, count) => {
                return {
                    name,
                    count,
                };
            })
        chart.render();
    }, [chartData, id, titleName])

    useEffect(() => {
        
        setTotal(0)
        document.getElementById(id).innerHTML=null
        if (data) {
            getChartData()

            chartData.forEach(element => {
                element.value = Math.floor(parseFloat(element[title]) ) / 10000;
                if(element.timer !== '去年同期数据'){
                    setTotal(total=>total+parseFloat(element[title]) )
                }
            });
            
            renderChart()
        }
    }, [data,id,title,titleName,timer, chartData, getChartData, renderChart])

    return (
        <div className={style.box}>
            <div className={style.header}>
                <div className={style.left}>
                    <div className={style.title}>{timer[0] + '-' + timer[1]}
                        <span className={style.special}> {sessionStorage.getItem('cityName') ||'全国'} {sessionStorage.getItem('type')} </span>累计{titleName}金额（万元）
                    </div>
                    <div className={style.data}>{formatPrice(Math.floor(total) / 10000)}</div>
                </div>
                <div className={style.right}>
                    <DateRangePicker timer={timer} changeTime={(date) => changeTime(date)} />
                </div>
            </div>
            <div id={id}></div>
        </div>
    )
}

sessionStorage.removeItem("cityName");
sessionStorage.removeItem("type");

export default DataView