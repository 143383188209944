import React from "react"
import HomePage from "../components/home/index"

const Home = (props) => {
    return (
        <div>
            <HomePage {...props}></HomePage>
        </div>
    )
}
export default Home