import React, { useState, useEffect, useCallback } from "react"
import { Chart } from '@antv/g2';
import DataSet from '@antv/data-set'
import style from './style.module.scss'
import DateRangePicker from './DateRangePicker'
const id = 'circleChart'

const DataView = (props) => {
  const {data, timer, dataType, getSingleData, titleName} = props
  const [chartData, setChartData] = useState(data)

  // 设置chart数据
  const getChartData = useCallback((params) => {
    if (params) { // 子元素改变时间
        getSingleData(params)
    }
    
    setChartData(data)
  }, [data, getSingleData])

  const changeTime = useCallback((date) => {
      document.getElementById(id).innerHTML = null
      getChartData({ part: 7, start: date[0], end: date[1], dataType })
  }, [getChartData, dataType])

  const renderChart = useCallback(() => {
    const ds = new DataSet();
    const dv = ds.createView().source(chartData);
    dv.transform({
      type: 'fold',
      fields: ['住宅', '商业', '工业'], // 展开字段集
      key: '类型', // key字段
      value: '成交量', // value字段
      retains: ['circ'], // 保留字段集，默认为除fields以外的所有字段
    });

    const chart = new Chart({
      container: id,
      autoFit: true,
      height: 500,
    });

    chart.coordinate().transpose();

    chart.data(dv.rows);
    chart.scale('成交量', { nice: true });

    chart.axis('circ', {
      label: {
        offset: 12,
      },
    });

    chart.tooltip({
      shared: true,
      showMarkers: false,
    });

    chart
      .interval()
      .adjust('stack')
      .position('circ*成交量')
      .color('类型');

    chart.interaction('active-region');

    chart.render();
  },[chartData])

  useEffect(() => {
    document.getElementById(id).innerHTML = null
    if (data) {
      getChartData()
      renderChart()
    }
  }, [renderChart, timer, data, chartData, getChartData])
  return (
    <div className={style.box}>
      <div className={style.header}>
          <div className={style.left}>
              <div className={style.title}>{timer[0] + '-' + timer[1]}
              <span className={style.special}> {sessionStorage.getItem('cityName') ||'全国'} {sessionStorage.getItem('type')} </span> 各拍卖阶段{titleName}量数据（套）
              </div>
          </div>
          <div className={style.right}>
              <DateRangePicker timer={timer} changeTime={(date) => changeTime(date)} />
          </div>
      </div>
      <div className={style.loop} id={id}></div>
  </div>
  )
}
export default DataView